/* 
This @exports
	quotationsGet(options)
	quotationsSendForm(options)
This API wrapper is useful because it:
   1. Centralizes our Axios default configuration.
   2. Abstracts away the logic for determining the baseURL.
   3. Provides a clear, easily consumable list of JavaScript functions
      for interacting with the API. This keeps API calls short and consistent. 
*/
import axios from 'axios';

let api = null;
var baseUrl = null;

function getInitializedApi() {
    if (api) return api; // return initialized api if already initialized.
    return (api = axios.create({
        baseURL: getBaseUrl(),
        responseType: 'json',
        withCredentials: false,
    }));
}

// Helper functions
function getBaseUrl() {
    // Insert logic here to get the baseURL by either:
    // 1. Sniffing the URL to determine the environment we're running in.
    // 2. Looking for an environment variable as part of the build process.
    return baseUrl;
}

function get(url) {
    return getInitializedApi().get(url);
}

function post(url, data) {
    return getInitializedApi().post(url, data);
}

// ================================================================================== Public

export function config(data) {
    baseUrl = data.baseUrl;
}

export function couponValidate(body) {
    return post('coupon_validate', body);
}

export function quotationsGet(body) {
    return post('quote', body);
}

export function quotationGenerate(options) {
    return post('genquote', options);
}
