/**
 * Step Component
 *
 * @prop {React.Component} stepComponent
 * @prop {Number} stepNum
 * @prop {JSX} stepBody
 */
import React, { Component } from 'react';

import watch from 'redux-watch';

import appStore from '../../../redux/store/appStore';
import { guiWizardNextautostepWatcherAdd } from '../../../redux/actions/actions';

import { gaTrackPage } from '../../../helpers/google-analytics-helper';

import {
    urlRewrite,
    currentUrlAddSearchString,
    navigationByWizardControlIsRunning,
    navigationByWizardControlsEnd,
    stepComponentBrowserNavigationSetup,
} from '../../../helpers/browser-navigation-helper';

import {
    stepUrlPathGet as wizardStepUrlPathGet,
    nextAuto,
    nextPermissionManage,
    jumpToStep,
} from '../../../helpers/wizard-helper';

import { getItem } from '../../../helpers/object-helper';

import './Step.css';

class Step extends Component {
    componentDidMount() {
        // subscribes next permission listener to store changes
        appStore.subscribe(this.nextPermissionListener.bind(this));

        // setup automatic next navigation
        this.nextAutoSetup();

        // Sets up browser navigation for this Step
        stepComponentBrowserNavigationSetup(
            this.props.stepComponent,
            this.props.stepNum
        );

        // Synchs the shown Wizard Step to the current url (useful for page quotations back button)
        jumpToStep(this.props.stepComponent, this.props.stepNum);

        // add Step to browser history only if landing from a wizard button navigation click
        if (navigationByWizardControlIsRunning()) {
            // this.props.history.push(
            //     urlRewrite(wizardStepUrlPathGet(this.props.stepNum))
            // );
            window.history.pushState(
                {},
                '',
                urlRewrite(wizardStepUrlPathGet(this.props.stepNum))
            );
            navigationByWizardControlsEnd();
            currentUrlAddSearchString();
        }

        // Google Analytics track page
        gaTrackPage();
    }

    /**
     * Sets up automatic next navigation on Step value (user choice driven)
     */
    nextAutoSetup() {
        this.createNextAutoWatcher(
            this.props.stepNum,
            this.props.stepComponent.editingDataId
        );
    }

    /**
     * Creates nextAuto watcher function for stepNum Step
     * The watcher is a function which is invoked when the Step's related application store data changes (which occurs on the user choices)
     * @param {Number} stepNum
     *  Sample
     *      2
     * @param {String} statePath
     *  Sample
     *      'configuration.roofOrientation'
     */
    createNextAutoWatcher(stepNum, statePath) {
        // get application state and listener id
        const appState = appStore.getState();
        const listenerId = 'step' + stepNum;

        // if watcher already exists for listenerId -> return
        if (getItem('gui.wizardNextautoWatchers', appState)[listenerId]) {
            return;
        }

        // create watcher
        let watcher = watch(appStore.getState, statePath);
        appStore.subscribe(
            watcher((newVal, oldVal, objectPath) => {
                nextAuto(this.props.stepComponent, this.props.stepNum);
            })
        );

        // register watcher for stepNum Step (to avoid multiple registrations of the same watcher)
        appStore.dispatch(
            guiWizardNextautostepWatcherAdd({
                listenerId: listenerId,
            })
        );
    }

    /**
     * Redux store listener
     * Triggers next button permission management when the current Step Component property changes value (the user makes his choices...)
     * This listener responds to all the redux store properties changes, so we must restrict
     */
    nextPermissionListener() {
        if (
            getItem('gui.currentEditingData', appStore.getState()) ===
            this.props.stepComponent.editingDataId
        ) {
            nextPermissionManage(
                this.props.stepComponent,
                this.props.stepNum,
                false
            );
        }
    }

    render() {
        return (
            <div className={'slc-step slc-step-' + this.props.stepNum}>
                <div className="slc-step-body">{this.props.stepBody}</div>
            </div>
        );
    }
}

export default Step;
