import React from 'react';

const Step5Infos = (props) => {
    return (
        <div className="slc-wizard-infos-step slc-wizard-infos-step-5">
        Indica da quante persone è composto il tuo nucleo familiare.<br />
        È stato stimato, sulla base di dati statistici e della nostra esperienza, che i consumi medi di una abitazione unifamiliare (senza la presenza di particolari apparecchiature quali pompe di calore, etc) siano di 2000 kWh annui, e per ogni abitante vengono stimati 1000 kWh annui aggiuntivi.
        </div>
    );
}

export default Step5Infos;