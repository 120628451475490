/**
 * useHeaderTitle Custom Hook
 * Used to expose HeaderTitle functional component utilities that are testable
 * @param {String} title 
 */

const useHeaderTitle = (title) => {

    const hasTitle = () => {
        return (title !== null && title !== undefined);
    };

    return {
        hasTitle
    };

};

export default useHeaderTitle;