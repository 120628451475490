import React from "react";

const defaults = {
  className: 'html-button',
  label: 'my-label',
  onClick: evt => {}
};

const HtmlButton = ({
    className,
    label,
    onClick
}) => {

  className = className || defaults.className;
  label = label || defaults.label;
  onClick = onClick || defaults.onClick;

  return (
    <button
        className={ className }
        onClick={ onClick }>
            { label }
        </button>
  );
  
};

export default HtmlButton;
