/**
 * Hints Component
 *  At the moment renders nothing:
 *  the three boxes rednering implementation has been abandoned by a specification update
 *  The three boxes implementation has been commented out, in case it will be required again in future specifications updates
 */

import React, { Component } from 'react';

import './Hints.css';

/*
import imgIconShield from '../../../images/quotations/icon-shield.jpg';
import imgIconMan from '../../../images/quotations/icon-man.jpg';
import imgIconSmartphone from '../../../images/quotations/icon-smartphone.jpg';
import imgIconNuclear from '../../../images/quotations/icon-nuclear.jpg';
import imgIconTrees from '../../../images/quotations/icon-trees.jpg';
import imgIconHome from '../../../images/quotations/icon-home.jpg';
import imgIconBike from '../../../images/quotations/icon-bike.jpg';
*/

class Hints extends Component {

    render() {
        return null;
    }

    /**
    render() {
        return(
            <div className='slc-quotations-hints slc-grid slc-grid-3col'>
                <div className="slc-grid-row">
                    <div className="slc-grid-cell">
                        <div className="slc-box">
                            <div className="slc-box-header slc-font-title">
                            I vantaggi di scegliere un sistema W&ouml;lmann
                            </div>
                            <div className="slc-box-body">
                                <div className="slc-box-item slc-box-item--icon-txt">
                                    <img className="slc-box-item--icon-txt__icon" src={imgIconShield} alt="" />
                                    <div className="slc-box-item--icon-txt__txt">
                                        Gli unici in Italia a garantire la produzione del sistema
                                    </div>
                                </div>
                                <div className="slc-box-item slc-box-item--icon-txt">
                                    <img className="slc-box-item--icon-txt__icon" src={imgIconMan} alt="" />
                                    <div className="slc-box-item--icon-txt__txt">
                                        Sistema chiavi in mano e tutto incluso con 5 anni di garanzia di montaggio
                                    </div>
                                </div>
                                <div className="slc-box-item slc-box-item--icon-txt">
                                    <img className="slc-box-item--icon-txt__icon" src={imgIconSmartphone} alt="" />
                                    <div className="slc-box-item--icon-txt__txt">
                                        Controllare il sistema e la tua energia dove e quando vuoi
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slc-grid-cell">
                        <div className="slc-box">
                            <div className="slc-box-header slc-font-title">
                            Il solare conviene?
                            </div>
                            <div className="slc-box-body">
                            <table className="slc-table slc-table-solare-conviene">
                                <tbody>
                                    <tr>
                                        <td>Il risparmio</td>
                                        <td>Per 1 anno</td>
                                        <td>Per 10 anni</td>
                                        <td>Per 25 anni</td>
                                    </tr>
                                    <tr>
                                        <td>Senza solare</td>
                                        <td>0,00&euro;</td>
                                        <td>0,00&euro;</td>
                                        <td>0,00&euro;</td>
                                    </tr>
                                    <tr>
                                        <td>Con Solare</td>
                                        <td>384,00&euro;</td>
                                        <td>4.512,00&euro;</td>
                                        <td>14.088,00&euro;</td>
                                    </tr>
                                    <tr>
                                        <td>Con solare e accumulo</td>
                                        <td>864,00&euro;</td>
                                        <td>10.152,00&euro;</td>
                                        <td>31.698,00&euro;</td>
                                    </tr>
                                    <tr>
                                        <td>Debito energetico</td>
                                        <td>960,00&euro;</td>
                                        <td>11.280,00&euro;</td>
                                        <td>35.220,00&euro;</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div className="slc-grid-cell">
                        <div className="slc-box">
                            <div className="slc-box-header slc-font-title">
                                Il tuo contributo ambientale
                            </div>
                            <div className="slc-box-body">
                                <div className="slc-box-item slc-box-item--icon-txt">
                                    <img className="slc-box-item--icon-txt__icon" src={imgIconNuclear} alt="" />
                                    <div className="slc-box-item--icon-txt__txt">
                                        Le emissioni di CO2 diminuiscono di -2,49 t/annuo
                                    </div>
                                </div>
                                <div className="slc-box-item slc-box-item--icon-txt">
                                    <img className="slc-box-item--icon-txt__icon" src={imgIconTrees} alt="" />
                                    <div className="slc-box-item--icon-txt__txt">
                                        Numero nuovi alberi piantati 120
                                    </div>
                                </div>
                                <div className="slc-box-item slc-box-item--icon-txt">
                                    <img className="slc-box-item--icon-txt__icon" src={imgIconHome} alt="" />
                                    <div className="slc-box-item--icon-txt__txt">
                                        La classe energetica di casa aumenta di 4 kWh/mq annuo
                                    </div>
                                </div>
                                <div className="slc-box-item slc-box-item--icon-txt">
                                    <img className="slc-box-item--icon-txt__icon" src={imgIconBike} alt="" />
                                    <div className="slc-box-item--icon-txt__txt">
                                        La tua e-bike non si ferma mai 11 giri del mondo
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
     */
    
}

export default Hints;