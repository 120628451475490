import React from 'react';

import Form from './Form';
import FormCoupon from './FormCoupon';

import { ReactComponent as CloseIcon } from '../../../../images/quotations/close.svg';
import './FormModal.css';

const FormModal = ({ onCloseButtonClick, onRestartClick }) => {
    return (
        <div
            id="form-modal"
            className="modal slc-modal"
            style={{ display: 'block' }}>
            <div className="slc-modal-header">
                <div className="slc-modal-close" onClick={onCloseButtonClick}>
                    <CloseIcon className="slc-modal-close-icon" />
                </div>
            </div>
            <div className="modal-content slc-modal-content">
                <FormCoupon />
                <Form onRestartClick={onRestartClick} />
            </div>
        </div>
    );
};

export default FormModal;
