import React from "react";

const defaults = {
  className: "html-select",
  options: [
      { label: 'Choose...', value: -1 },
      { label: 'One', value: 1 },
      { label: 'Two', value: 2 }
  ],
  value: -1,
  onChange: evt => {}
};

const HtmlSelect = ({
    className,
    options,
    value,
    onChange
}) => {

  className = className || defaults.className;
  options = options || defaults.options;
  value = value || defaults.value;
  onChange = onChange || defaults.onChange;

  return (
    <select className={ className } onChange={ onChange }>
        { options.map(option => (
            <option
                key={ value + Math.random() }
                value={ option.value }
                selected={ option.value === value }>
                { option.label }
            </option>
        )) }
    </select>
  );

};

export default HtmlSelect;
