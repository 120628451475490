import { createBrowserRouter } from 'react-router-dom';
import { getConfUrlBasePathName, getStepsNum } from '../conf/conf';
import PageHome from '../components/pages/PageHome';
import PageWizard from '../components/pages/PageWizard';
import PageQuotations from '../components/pages/quotations/PageQuotations';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const createRouter = (routes) =>
    createBrowserRouter(routes, {
        basename: getConfUrlBasePathName(),
    });

const getRoutes = () => [
    {
        path: '/',
        element: <PageHome />,
    },
    {
        path: 'wizard',
        element: <PageWizard />,
    },
    ...getWizardStepsRoutes(getStepsNum()),
    {
        path: 'preventivo',
        element: <PageQuotations />,
    },
];

const getWizardStepsRoutes = (stepsNum) =>
    Array.from({ length: stepsNum }).map((_, index) => ({
        path: `wizard/step${index + 1}`,
        element: <PageWizard />,
    }));

const routerize = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
};

export { createRouter, getRoutes, routerize };
