import React from "react";
import appStore from '../../redux/store/appStore';
import HtmlPlusMinusModel from './HtmlPlusMinusModel';
import { valueIsBestQuotation, renderDataAsBestQuotation } from '../../helpers/quotations-helper';

const defaults = {
    className: 'html-plus-minus',
    items: [
        {label: '6 nodi', value: 6},
        {label: '8 nodi', value: 8},
        {label: '9 nodi', value: 9}
    ],
    value: 8,
    onClickPlusButton: evt => {},
    onClickMinusButton: evt => {}
};

const HtmlPlusMinus = ({
    className,
    items,
    value,
    onClickPlusButton,
    onClickMinusButton
}) => {

    className = className || defaults.className;
    items = items || defaults.items;
    onClickPlusButton = onClickPlusButton || defaults.onClickPlusButton;
    onClickMinusButton = onClickMinusButton || defaults.onClickMinusButton;

    const [selectedItem, setSelectedItem] = React.useState(HtmlPlusMinusModel.getItemByValue(items, value || defaults.value));
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(HtmlPlusMinusModel.getItemIndexByValue(items, value || defaults.value));
    const domRef = React.useRef(null);
    
    React.useEffect(() => {
        selectItem(HtmlPlusMinusModel.getItemByValue(items, value), items);
    }, [items, value]);

    /**
     * Selects item
     * @param {Object} item
     *  .label {String}
     *  .value {Mixed}
     * @param {Object[]} items 
     *  [n]
     *      .label {String}
     *      .value {Mixed}
     */
    const selectItem = (item, items) => {
        if (!item) {
            return;
        }
        const itemIndex = HtmlPlusMinusModel.getItemIndex(items, item);
        if (itemIndex >= 0) {
            // write the new value into the dom, to have it immediately available for inputsSnapshot logic
            domUpdateSelectedItemValue(item.value);
            // render as best quotation or not
            domRenderBestQuotation(item);
            // update selectedItem and selectedItemIndex (all state updates are handled async by react, so new values are not immediately available)
            setSelectedItem(item);
            setSelectedItemIndex(itemIndex);
        }
    };

    /**
     * Returns if plus button is disabled
     * @returns {Boolean}
     */
    const plusButtonDisabled = () => (selectedItemIndex === items.length - 1);

    /**
     * Returns if minus button is disabled
     * @returns {Boolean}
     */
    const minusButtonDisabled = () => (selectedItemIndex === 0);

    /**
     * Plus button on click event listener
     * @param {Event} evt 
     */
    const onPlus = evt => {

        // return if button is disabled
        if (plusButtonDisabled()) {
            return;
        }

        // select next item
        const nextItem = HtmlPlusMinusModel.getNextItem(items, selectedItem);
        const nextItemIndex = HtmlPlusMinusModel.getItemIndex(items, nextItem);
        selectItem(nextItem, items);

        // call click event listener callback
        evt.target = domRef.current;
        onClickPlusButton(evt);

    };
    
    /**
     * Minus button on click event listener
     * @param {Event} evt 
     */
    const onMinus = evt => {

        // return if button is disabled
        if (minusButtonDisabled()) {
            return;
        }

        // select previous item
        const prevItem = HtmlPlusMinusModel.getPrevItem(items, selectedItem);
        const prevItemIndex = HtmlPlusMinusModel.getItemIndex(items, prevItem);
        selectItem(prevItem, items);

        // call click event listener callback
        evt.target = domRef.current;
        onClickMinusButton(evt);

    };

    /**
     * Updates the Component dom ref's data-selected-value attribute with value
     * @param {String} value 
     */
    const domUpdateSelectedItemValue = (value) => {
        domRef.current.setAttribute('data-selected-value', value);
    };

    /**
     * Renders item's related HTMLElement/s as a best quotation value
     * @param {Object} item 
     */
    const domRenderBestQuotation = item => {

        // read the item's value
        const value = item.value;

        // get params needed to check if the item's value is the best quotation value
        const dataFullId = domRef.current.parentNode.getAttribute('data-option-id'); // 'potenza-basic'
        const [dataId, quotationType] = dataFullId.split('-'); // 'potenza', 'basic'
        const bestQuotations = appStore.getState().bestquotations;

        // check if tem's value is the best quotation value
        const isBestValue = valueIsBestQuotation(value, quotationType, dataId, bestQuotations);

        // get the HTMLElement to be rendered as best quotation (the element representing the data value itself)
        const $dataValueElement = domRef.current.getElementsByClassName('slc-plus-minus__label')[0];

        // call the best quotation render function 
        renderDataAsBestQuotation(dataId, isBestValue, $dataValueElement);

    };
    

    /**
     * Returns the css class name for buttonType button
     * @param {String} baseCssClassName 
     * @param {String} buttonType 
     *  ['plus'|'minus']
     * @returns {String}
     *  Samples:
     *      'slc-plus-minus__button slc-plus-minus__button-minus'
     *      'slc-plus-minus__button slc-plus-minus__button-plus slc-plus-minus__button-disabled'
     */
    const buttonCssClassName = (baseCssClassName, buttonType) => {
        const disabledFunction = buttonType === 'plus' ?
            plusButtonDisabled :
            minusButtonDisabled;
        const disabled = disabledFunction() ?
            ` ${ baseCssClassName }__button-disabled` :
            ``;
        return `${ baseCssClassName }__button ${ baseCssClassName }__button-${ buttonType }${ disabled }`;
    };

    return (
        <div className={ className } data-selected-value={ selectedItem.value } ref={ domRef }>
            <div
                className={ buttonCssClassName(className, 'minus') }
                onClick={ onMinus }>
                -
            </div>
            <div dangerouslySetInnerHTML={{
                __html: selectedItem.label
              }} className={ `${ className }__label`} />
            <div
                className={ buttonCssClassName(className, 'plus') }
                onClick={ onPlus }>
                +
            </div>
        </div>
    );
  
};

export default HtmlPlusMinus;
