/**
 * PageHome Component
 * Connected to redux
 *
 * @prop gui {String}
 *  ['desktop'|'mobile']
 * @state {Object}
 *  .navPath {String}
 */

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import appStore from '../../redux/store/appStore';
import { storeReset, updateUser } from '../../redux/actions/actions';

import PageLayout from '../layout/PageLayout';
import Header from '../Header';
import PageHomeContent from './PageHomeContent';
import Footer from '../Footer';

import { gaTrackPage } from '../../helpers/google-analytics-helper';
import { navigationByWizardControlsStart } from '../../helpers/browser-navigation-helper';
import { initialize as autocompleteInitialize } from '../../helpers/google-places-autocomplete-helper';
import { warn as guiFeedbackWarn } from '../../libs/gui-feedback';
import getResponsiveContent from '../../helpers/responsive-content-home-helper';

import './Page.css';
import './PageHome.css';

const title = 'Pochi passi per risparmiare con i pannelli solari';
const infos = 'Scopri il Sistema Solare più adatto a te e calcola il prezzo';

const PageHome = () => {
    const [navPath, setNavPath] = useState(null);
    const gui = useSelector((state) => state.gui);

    useEffect(() => {
        appStore.dispatch(storeReset());
        gaTrackPage();
    }, []);

    const autocompleteInit = () => {
        try {
            const autocomplete = autocompleteInitialize({
                element: document.getElementById('user-address'),
            });
        } catch (e) {
            console.error(e.name + ': ' + e.message);
        }
    };

    const onSubmit = () => {
        const userAddress = document.getElementById('user-address').value;
        const userEmail = document.getElementById('user-email').value;

        if (userAddress === '') {
            guiFeedbackWarn("E' necessario inserire un indirizzo");
            return;
        }

        appStore.dispatch(
            updateUser({
                address: userAddress,
                email: userEmail,
            })
        );
        navigationByWizardControlsStart();
        setNavPath('/wizard');
    };

    return navPath ? (
        <Navigate to={{ pathname: navPath }} />
    ) : (
        <PageLayout
            pageId="home"
            header={<Header title={false} />}
            body={
                <PageHomeContent
                    responsive={gui.responsive}
                    onSubmit={onSubmit}
                    autocompleteInit={autocompleteInit}
                    title={title}
                    infos={infos}
                    btnTxt={getResponsiveContent('btnTxt', gui.responsive)}
                    imagePath={getResponsiveContent('image', gui.responsive)}
                />
            }
            footer={<Footer />}
        />
    );
};

export default PageHome;
