import React, { Component } from 'react';
import { connect } from "react-redux";

import {
    getLabel,
    getMeasureUnit,
    getValueProcessFn,
    getValueGuiFn,
    getValueGuiForConfigurationStateFn
} from '../conf/conf';

const mapStateToProps = state => {
    return {
        gui: state.gui
    };
};

class ConfigurationStateItem extends Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({}, props);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return Object.assign(prevState, nextProps);
    }    

    getItemClassName (itemId) {
        let className = 'slc-configuration-state__item';
        if (itemId === this.props.gui.currentEditingData) {
            className += ' slc-editing-data'
        }
        return className;
    }

    render() {

        var itemValue = this.state.itemValue;
        const itemPath = this.props.itemPath;
        const itemLabel = getLabel(itemPath, this.props.itemName);
        const measureUnit = getMeasureUnit(itemPath);
        const valueProcessFn = getValueProcessFn(itemPath);
        if (valueProcessFn) {
            itemValue = valueProcessFn(itemValue);
        }
        const valueGuiFn = getValueGuiFn(itemPath);
        const valueGuiForConfigurationStateFn = getValueGuiForConfigurationStateFn(itemPath);
        if (valueGuiForConfigurationStateFn) {
            itemValue = valueGuiForConfigurationStateFn(itemValue);
        } else if (valueGuiFn) {
            itemValue = valueGuiFn(itemValue);
        }

        return (
            <div key={itemPath} className={this.getItemClassName(itemPath)} data-dataid={itemPath}>
                <div className="slc-configuration-state__item__label">{itemLabel}</div>
                <div className="slc-configuration-state__item__value">{itemValue} {measureUnit}</div>
            </div>
        );
        
    }

}

export default connect(mapStateToProps)(ConfigurationStateItem);