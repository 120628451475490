const PATH_SEPARATOR = '.';

/**
 * Reads and returns the object's item identified by itemPath
 * @param {String} itemPath
 * @param {Object} object
 * @return {Mixed|null}
 * 
 * @usage
 *  const obj = {
 *      user: {
 *          profile: {
 *              name: 'Andrea',
 *              birth: '2000-01-27',
 *          }
 *      },
 *      location: {
 *          name: 'Milano'
 *      }
 *  }
 *  const item = getItem('user', obj);
 *      {profile:{name:'Andrea', birth:'2000-01-27'}} 
 *  const item = getItem('user.profile.name', obj); 
 *      'Andrea'
 *  const item = getItem('location.name', obj); 
 *      'Milano'
 */
const getItem = (itemPath, object = {}, separator = PATH_SEPARATOR) => {

    // get access keys as an array
    const itemAccessKeys = itemPath.split(separator);

    // extract first key
    const itemAccessKey = itemAccessKeys.shift();

    // read item with the exctracted key
    const readItem = object[itemAccessKey];

    // if it was the last key, return the item
    if (itemAccessKeys.length === 0) {
        return readItem;
    // if more keys are left to read, recurse
    } else {
        if (readItem) {
            return getItem(itemAccessKeys.join(separator), readItem);
        } else {
            return null;
        }
        
    }
    
};

/**
 * Returns if any of the obj's properties satisfies the testFn test
 * The test is not a deep one (only the first level properties are evaluated)
 * @param {Function} testFn
 *  Function against which each obj's property is evaluated
 *  Accepts two arguments, property name and property value
 * @param {Object} obj 
 * @returns {Boolean}
 */
const some = (testFn, obj) => {
    var match = false;
    for (let prop in obj) {
        if (testFn(prop, obj[prop])) {
            match = true;
            break;
        }
    }
    return match;
};

const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
};
  
const isObject = object => {
    return object != null && typeof object === 'object';
};

export {
    getItem,
    some,
    deepEqual
};