import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import FormCouponTitle from './FormCouponTitle';
import FormCouponDiscounts from './FormCouponDiscounts';
import { LoadingBar } from '../../../Loader/LoadingBar';
import { couponValidate } from '../../../../libs/http-client';
import {
    COUPON_MESSAGE_ERROR,
    COUPON_MESSAGE_VALID,
    COUPON_REGEX,
    couponValidationSucceeded,
    isInvalidCoupon,
    isValidCoupon,
    updateStoreCoupon,
} from './FormCoupon.utils';
import { ok, warn } from '../../../../libs/gui-feedback';
import { couponValidateGetRequestPayload } from '../../../../libs/http-client-dataManager';

import './Form.css';
import './FormCoupon.css';
import '../../../Loading.css';

const FormCoupon = () => {
    const dispatch = useDispatch();

    const couponValidateMutation = useMutation({
        mutationFn: couponValidate,
        onSuccess: (axiosResponse) => {
            const couponData = axiosResponse.data;

            updateStoreCoupon(couponData, dispatch);

            if (isValidCoupon(couponData)) {
                onValidCoupon(COUPON_MESSAGE_VALID);
            }

            if (isInvalidCoupon(couponData)) {
                onInvalidCoupon(couponData.message);
            }
        },
        onError: () => {
            warn(COUPON_MESSAGE_ERROR, {
                preventDuplicates: false,
            });
        },
    });

    const {
        register,
        handleSubmit,
        formState: {
            errors: formErrors,
            isValid: formIsValid,
            isSubmitting: formIsSubmitting,
        },
        setValue,
    } = useForm();

    const onValidCoupon = (message) => {
        ok(message, {
            preventDuplicates: false,
        });
    };

    const onInvalidCoupon = (message) => {
        warn(message, {
            preventDuplicates: false,
        });
        setValue('coupon', '');
    };

    const onSubmit = (data) => {
        if (!formIsValid || couponValidateMutation.isPending) {
            return;
        }
        couponValidateMutation.mutate(
            couponValidateGetRequestPayload(data.coupon)
        );
    };

    const showDiscounts =
        !formIsSubmitting && couponValidationSucceeded(couponValidateMutation);

    return (
        <div className="slc-coupon-form-container">
            <div className="slc-coupon-form__title slc-quotations-form__title slc-font-title">
                <FormCouponTitle />
            </div>
            <div className="slc-coupon-form__info">
                Se hai un codice promozionale, inseriscilo qui sotto e premi il
                bottone VALIDA per scoprire quale sconto ti offriamo
            </div>
            <form className="slc-coupon-form__form">
                <div className="slc-form-input-text-widget">
                    <div className="slc-form-input-text-widget__input-container slc-form-input-text-widget__input-container--with-error-msg">
                        <input
                            {...register('coupon', {
                                validate: (value) =>
                                    COUPON_REGEX.test(value) ||
                                    'Il coupon deve essere di almeno 4 caratteri alfanumerici',
                            })}
                            className={`slc-form-input-text-widget__input slc-form-input-text-widget__input-coupon${
                                formErrors.coupon
                                    ? ' slc-form-item-validation-error'
                                    : ''
                            }`}
                        />
                        {couponValidateMutation.isPending && (
                            <div className="slc-loading-bar-container">
                                <LoadingBar />
                            </div>
                        )}
                    </div>
                </div>
                <button
                    onClick={handleSubmit(onSubmit)}
                    className="slc-button btn btn-primary btn-lg">
                    Valida
                </button>
            </form>
            {showDiscounts && (
                <FormCouponDiscounts
                    coupon={couponValidateMutation.data.data}
                />
            )}
        </div>
    );
};

export default React.memo(FormCoupon);
