/**
 * Step1 Component: roofArea
 */

import React, { Component, Fragment } from 'react';

import { getConfItem } from '../../../conf/conf';

import appStore from '../../../redux/store/appStore';
import { updateGui, updateConfiguration, updateMap, updateMapStaticImageUrl } from '../../../redux/actions/actions';

import Step from './Step';

//import { stepComponentBrowserNavigationSetup } from '../../../helpers/browser-navigation-helper';

import {
    initialize as mapInitialize,
    resetDraw as mapDrawReset,
    mapImageUrl,
    pathPointsToUrlValue
} from '../../../helpers/google-map-helper';

import { nextPermissionDeniedGuiFeedback } from '../../../helpers/wizard-helper';

import { warn as guiFeedbackWarn } from '../../../libs/gui-feedback';

import { getItem } from '../../../helpers/object-helper';

import './Step1.css';

const stepNum = 1;
const editingDataId = 'configuration.roofArea';

class Step1 extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.editingDataId = editingDataId;
        this.isValidated = this.isValidated.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        appStore.dispatch(updateGui({
            currentEditingData: editingDataId
        }));
        const roofArea = getItem(editingDataId, appStore.getState());
        if (roofArea) {
            this.updateInput(roofArea);
        }
        this.mapCreate();
    }

    mapCreate() {

        const Step1Self = this;

        const address = getItem('user.address', appStore.getState()) || getConfItem('gui.map.address'); 
        
        /**
         * map state format
         *  zoom: map.getZoom(),
            center: mapCenter,
            centerUrlValue: mapCenter.toUrlValue(),
            type: map.getMapTypeId(),
            polygon: {
                path: polygon.getPath(),
                pathUrlValue: mapShapePathToUrlValue(polygonPath),
                weight: polygon.strokeWeight,
                fillColor: polygon.fillColor,
                fillOpacity: polygon.fillOpacity
            }
         */

        const mapConfOptions = getConfItem('gui.map.mapOptions');

        /*
            TODO: activate if you want to remember the saved polygon
                  activate only if google-map-helper.polygonCreate(option) will be completed
        const mapState = getItem('map.data', appStore.getState());        
        const mapStateOptions = (!mapState) ? {} : {
            center: mapState.center,
            zoom: mapState.zoom
        };
        const mapOptions = {...mapConfOptions, ...mapStateOptions};
        */
        const mapOptions = {...mapConfOptions};
        
        mapInitialize({
            address: address,
            mapOptions: mapOptions,
            polygonOptions: getConfItem('gui.map.polygonOptions'),
            polygonCoords: [], // TODO: fill from redux state, if any, else = null
            drawingManagerOptions: getConfItem('gui.map.drawingManagerOptions'),
            onInitializedCallback: (map) => {
                const mapCenter = map.getCenter();
                appStore.dispatch(updateMap({
                    refs: {
                        map: map
                    },
                    data: {
                        zoom: map.getZoom(),
                        center: mapCenter,
                        centerUrlValue: mapCenter.toUrlValue(),
                        type: map.getMapTypeId(),
                        //polygon: polygonData
                    }
                }));
            },
            onMapCenterChangedCallback: (map) => {
                const mapCenter = map.getCenter();
                appStore.dispatch(updateMap({
                    data: {
                        center: mapCenter,
                        centerUrlValue: mapCenter.toUrlValue(),
                    }
                }));
            },
            onMapZoomChangedCallback: (map) => {
                appStore.dispatch(updateMap({
                    data: {
                        zoom: map.getZoom()
                    }
                }));
            },
            /**
             * Callback fired when the roof shape is updated
             *  1. Dispatches configuration roofArea update
             *  2. Dispatches map update
             *  3. Dispatches map static image url update
             *  4. Updates area input text value
             */
            updateShapeAreaCallback: (area, map, polygon) => {

                // update store configuration.roofArea
                area = (area) ? parseInt(area) : null;
                appStore.dispatch(updateConfiguration({
                    roofArea: area
                }));

                // update store map.data
                const mapCenter = map.getCenter();
                const polygonPath = (polygon) ? polygon.getPath() : null;
                const polygonData = (polygon) ? {
                    path: polygonPath,
                    pathUrlValue: decodeURIComponent(pathPointsToUrlValue(polygonPath)),
                    weight: polygon.strokeWeight,
                    fillColor: polygon.fillColor,
                    fillOpacity: polygon.fillOpacity
                } : null;
                appStore.dispatch(updateMap({
                    refs: {
                        map: map,
                        polygon: polygon
                    },
                    data: {
                        zoom: map.getZoom(),
                        center: mapCenter,
                        centerUrlValue: mapCenter.toUrlValue(),
                        type: map.getMapTypeId(),
                        polygon: polygonData
                        //...TODO: save location marker?
                    }
                }));

                // map static image url
                const url = mapImageUrl(map, polygon);
                appStore.dispatch(updateMapStaticImageUrl({
                    url: url
                }));
                
                // update area input text value
                document.querySelector('.slc-roofArea-input-text__input').value = area || '';
                // triggers validation
                Step1Self.isValidated();

            }
        });
    }

    /**
     * Step validation
     * @param {Boolean} showWarnMessage optional
     *  = undefined when call stack begins with btn next click, going through Stepzilla internals
     *      -> reacting to btn next click must show the warn message
     *  = Boolean (false) when call stack starts from Step.nextPermissionListener()
     *      -> reacting to redux store properties changes must not show the warn message
     */
    isValidated(showWarnMessage) {
        const area = appStore.getState().configuration.roofArea;
        const valid = (area !== null && area !== 0 && !isNaN(area) && area >= 8 && area <= 500);
        if (!valid && showWarnMessage === undefined) {
            nextPermissionDeniedGuiFeedback(this, stepNum, true);
        }
        return valid;
    }

    /**
     * On input event listener for area <input>
     */
    onInput(evt) {
        appStore.dispatch(updateConfiguration({
            roofArea: Number(evt.target.value)
        }));
        // uncomment this if we want to remove the polygon when the user inputs
        // we choose to leave the drawn polygon
        //mapDrawReset();
    }

    /**
     * On change event listener for area <input>
     */
    onChange(evt) {
        this.isValidated();
    }

    /**
     * Returns the area <input> HTMLElement
     * @return HTMLElement
     */
    domGetInput() {
        return document.querySelector('.slc-roofArea-input-text__input');
    }

    /**
     * Updates area <input> value
     * @param {Number} area 
     */
    updateInput(area) {
        this.domGetInput().value = area;
    }

    body() {
        return (
            <Fragment>
                <div className="slc-roofArea">
                    <div className="slc-roofArea-input-draw-clear-btn">Cancella</div>
                    <div className="slc-roofArea-input-draw"></div>
                    <div className="slc-roofArea-input-text">
                        <span className="slc-roofArea-input-text__label">Oppure inserisci i metri quadri del tuo immobile</span>
                        <div className="slc-form-input-text-widget__input-container">
                            <input type="text" onInput={this.onInput} onChange={this.onChange} className="slc-form-input-text-widget__input slc-roofArea-input-text__input" />
                            <div className="slc-form-input-text-widget__input-measure-unit">mq</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    render() {
        return <Step 
                stepComponent={this}
                stepNum={stepNum}
                stepBody={this.body()} />;
    }
    
}

export default Step1;