import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { getConfItem } from '../conf/conf';
import {
    getAltQuotations,
    extractAltquotationsPropertiesFromQuotations,
} from './altquotations-store-helper';
import { getBatteriesForPower } from './batteries-store-helper';
import { getItem } from './object-helper';
import HtmlPlusMinusModel from '../components/HtmlInput/HtmlPlusMinusModel';

/**
 * Helper for quotations page and functionalities
 */

/**
 * Returns the quotation type from dataOptionId
 * @param {String} dataOptionId
 *  Sample
 *      'accumulatoreProposto-basic'
 * @return {String}
 *  ['basic'|'plus']
 *  Sample
 *      'basic'
 */
const quotationTypeFromOptionId = (dataOptionId) => {
    return dataOptionId.split('-')[1];
};

/**
 * Returns the quotation type from dataOptionId
 * @param {String} dataOptionId
 *  Sample
 *      'accumulatoreProposto-basic'
 * @return {String}
 *  Sample
 *      'accumulatoreProposto'
 */
const optionNameFromOptionId = (dataOptionId) => {
    return dataOptionId.split('-')[0];
};

/**
 * Returns the 'option' data path related to the dataOptionId option
 * The data path is the acces key to the data value in the PageQuotations this.state.data
 * @param {String}
 *  Sample
 *      'accumulatoreProposto-basic'
 * @return {String}
 *  Sample
 *      'accumulatoreProposto.options.basic'
 */
const dataOptionPathFromOptionId = (dataOptionId) => {
    const tmp = dataOptionId.split('-');
    return tmp[0] + '.options.' + tmp[1];
};

/**
 * Returns the impianto selected by the user
 * Two factors drive the impianto calculation
 *  1. Which quotation type the user has selected (basic or plus)
 *  2. Which options (checkboxes) the user has choosen for the selected quotation type
 * @param {String} quotationType
 *  The quotation type
 *  ['basic'|'plus']
 * @param {Object} optionsSelectionState
 *  The state of the options (checkboxes) selection involved in the calculation of the impianto selected
 *  .accumulatoreProposto-basic {Boolean}
 *  .accumulatoreProposto-plus {Boolean}
 *  .ottimizzatori-plus {Boolean}
 * @returns {String}
 *  ['basic'|'basic-s'|'plus'|'plus-s']
 * @usage
 *  getImpiantoBySelection('basic', getOptionsSelectionState())
 *  getImpiantoBySelection('plus', getOptionsSelectionState())
 */
const getImpiantoBySelection = (quotationType, optionsSelectionState) => {
    let impianto, ottimizzatoriSelected;

    switch (quotationType) {
        case 'basic':
            ottimizzatoriSelected =
                optionsSelectionState['ottimizzatori-basic'];
            switch (true) {
                // only ottimizzatori selected
                case ottimizzatoriSelected:
                    impianto = 'basic-s';
                    break;
                // nothing selected
                default:
                    impianto = 'basic';
            }
            break;
        case 'plus':
            ottimizzatoriSelected = optionsSelectionState['ottimizzatori-plus'];
            switch (true) {
                // only ottimizzatori selected
                case ottimizzatoriSelected:
                    impianto = 'plus-s';
                    break;
                // nothing selected
                default:
                    impianto = 'plus';
            }
            break;
    }

    return impianto;
};

/**
 * Returns an Object snapshotting the current quotations options (checkboxes) state
 * @returns {Object}
 *  .ottimizzatori-basic {Boolean}
 *  .ottimizzatori-plus {Boolean}
 */
const getOptionsSelectionState = () => {
    const ottimizzatoriBasicCheckbox = document.querySelector(
        '[data-option-id="ottimizzatori-basic"] .slc-checkbox'
    );
    const ottimizzatoriPlusCheckbox = document.querySelector(
        '[data-option-id="ottimizzatori-plus"] .slc-checkbox'
    );
    return {
        'ottimizzatori-basic':
            ottimizzatoriBasicCheckbox && ottimizzatoriBasicCheckbox.checked,
        'ottimizzatori-plus':
            ottimizzatoriPlusCheckbox && ottimizzatoriPlusCheckbox.checked,
    };
};

/**
 * Returns the total for a specific quotation type
 * The quotation type is fetched from dataOptionId
 * @param {String} dataOptionId
 *  Range
 *      'accumulatoreProposto-basic'
 *      'accumulatoreProposto-plus'
 *      'ottimizzatori-plus'
 * @param {Object} totals
 *  .basic: "7.381,9"
 *  .basic-s: "9.921,9"
 *  .plus: "7.753,91"
 *  .plus-s: "8.959,91"
 */
const getTotaleBySelection = (dataOptionId, totals) => {
    const quotationType = quotationTypeFromOptionId(dataOptionId);
    const impianto = getImpiantoBySelection(
        quotationType,
        getOptionsSelectionState()
    );
    return totals[impianto];
};

/**
 * Returns the importoDetrazione for a specific quotation type
 * The quotation type is fetched from dataOptionId
 * @param {String} dataOptionId
 *  Range
 *      'accumulatoreProposto-basic'
 *      'accumulatoreProposto-plus'
 *      'ottimizzatori-plus'
 * @param {Object} importoDetrazione
 *  .basic: "7.381,9"
 *  .basic-s: "9.921,9"
 *  .plus: "7.753,91"
 *  .plus-s: "8.959,91"
 */
const getImportoDetrazioneBySelection = (
    dataOptionId,
    importoDetrazioneObj
) => {
    const quotationType = quotationTypeFromOptionId(dataOptionId);
    const impianto = getImpiantoBySelection(
        quotationType,
        getOptionsSelectionState()
    );
    return importoDetrazioneObj[impianto];
};

const getHtmlSelectDataPotenza = (potenzaArray) =>
    potenzaArray.map((pwrOption) => ({
        value: pwrOption,
        label: pwrOption + '',
    }));

const getHtmlSelectDataCapacitaSistemaAccumulo = (batteriesObject) =>
    batteriesObject.map((battery) => ({
        value: battery.label,
        label: battery.label,
        code: battery.code,
    }));

/**
 * Returns the data structure to feed an Html Select
 * @param {Oject} reduxState
 * @param {String} dataId
 *  Sample:
 *      'potenza'
 * @param {*} dataValueType
 *  Range:
 *      ['basic'|'plus']
 * @returns {Object[]}
 *  [n]
 *      .value {Mixed}
 *      .label {String}
 */
const getHtmlSelectDataFromReduxState = (reduxState, dataId, dataValueType) => {
    let options = [];

    const altquotations = getItem(`altquotations`, reduxState);
    //const batteries = getItem(`batteries`, reduxState);

    switch (dataId) {
        case 'potenza':
            if (altquotations) {
                options = getHtmlSelectDataPotenza(
                    altquotations[dataValueType].pwravail
                );
            }
            break;
        case 'capacitaSistemaAccumulo':
            /*
            const batteries = getItem(`batteries`, reduxState);
            //console.log('    batteries = ', batteries);
            if (batteries) {
                options = getHtmlSelectDataCapacitaSistemaAccumulo(batteries[dataValueType]);
            }
            */

            const selectedPower = reduxState.quotations.snapshot
                ? reduxState.quotations.snapshot.potenza[dataValueType]
                : '';
            const batteries = getBatteriesForPower(
                dataValueType,
                selectedPower
            );
            if (batteries) {
                options = getHtmlSelectDataCapacitaSistemaAccumulo(batteries);
            }
            break;
    }

    return options;
};

/**
 * Returns the value of an Html Select, reading from Redux state
 * @param {Oject} reduxState
 * @param {String} dataId
 *  Sample:
 *      'potenza'
 * @param {*} dataValueType
 *  Range:
 *      ['basic'|'plus']
 * @returns {Mixed}
 */
const getHtmlSelectValueFromReduxState = (
    reduxState,
    dataId,
    dataValueType
) => {
    let value = null;

    const snapshot = getItem(`quotations.snapshot`, reduxState);

    if (snapshot) {
        value = snapshot[dataId][dataValueType];
    }

    return value;
};

/**
 * Returns an Object snapshotting the current quotations inputs state
 * @returns {Object}
 *  .potenza
 *      .basic {String}
 *      .plus {String}
 *  .ottimizzatori
 *      .basic {Boolean}
 *      .plus {Boolean}
 *  .capacitaSistemaAccumulo
 *      .basic {String}
 *      .plus {String}
 */
const getInputsSnapshot = () => {
    const potenzaBasicInput = document.querySelector(
        '[data-option-id="potenza-basic"] .slc-plus-minus'
    );
    const potenzaPlusInput = document.querySelector(
        '[data-option-id="potenza-plus"] .slc-plus-minus'
    );

    const capacitaSistemaAccumuloBasicInput = document.querySelector(
        '[data-option-id="capacitaSistemaAccumulo-basic"] .slc-plus-minus'
    );
    const capacitaSistemaAccumuloPlusInput = document.querySelector(
        '[data-option-id="capacitaSistemaAccumulo-plus"] .slc-plus-minus'
    );

    const ottimizzatoriBasicCheckbox = document.querySelector(
        '[data-option-id="ottimizzatori-basic"] .slc-checkbox'
    );
    const ottimizzatoriPlusCheckbox = document.querySelector(
        '[data-option-id="ottimizzatori-plus"] .slc-checkbox'
    );

    return {
        potenza: {
            basic: getPlusMinusInputValueForSnapshot(potenzaBasicInput),
            plus: getPlusMinusInputValueForSnapshot(potenzaPlusInput),
        },
        capacitaSistemaAccumulo: {
            basic: getPlusMinusInputValueForSnapshot(
                capacitaSistemaAccumuloBasicInput
            ),
            plus: getPlusMinusInputValueForSnapshot(
                capacitaSistemaAccumuloPlusInput
            ),
        },
        ottimizzatori: {
            basic:
                ottimizzatoriBasicCheckbox &&
                ottimizzatoriBasicCheckbox.checked,
            plus:
                ottimizzatoriPlusCheckbox && ottimizzatoriPlusCheckbox.checked,
        },
    };
};

const getPlusMinusInputValueForSnapshot = (htmlPlusMinusInputElement) => {
    return htmlPlusMinusInputElement
        ? HtmlPlusMinusModel.readSelectedValueFromDom(htmlPlusMinusInputElement)
        : getConfItem('altquotations.featureNotAvailableValue');
};

/**
 *
 * @param {Object} reduxState
 * @param {String} quotationType
 *  ['basic'|'plus']
 * @param {Object} inputsSnapshot
 *  .ottimizzatori
 *      .basic {Boolean}
 *      .plus {Boolean}
 *  .potenza
 *      .basic {Number}
 *      .plus {Number}
 *  .capacitaSistemaAccumulo
 *      .basic {String}
 *      .plus {String}
 */
const readQuotationFromReduxStateByInputsSnapshot = (
    reduxState,
    quotationType,
    inputsSnapshot = getInputsSnapshot()
) => {
    let quotations, quotationsByInputsSnapshot;

    // get best quotations and quotationsSnapshot
    const bestQuotations = getItem(`bestquotations`, reduxState);
    const quotationsSnapshot = getItem(`quotations.snapshot`, reduxState);

    // retrieve the quotations (best or alternative) related to inputsSnapshot
    if (
        inputsSnapshotMatchBestQuotation(
            quotationType,
            bestQuotations,
            inputsSnapshot
        )
    ) {
        //console.log('IS BEST QUOTATION');
        quotationsByInputsSnapshot = bestQuotations;
    } else {
        //console.log('IS ALT QUOTATION');
        quotationsByInputsSnapshot = getAltQuotations(
            quotationType,
            inputsSnapshot
        );
    }

    // extract only the quotationType part
    const quotationOfType = extractQuotationOfTypeFromQuotations(
        quotationsByInputsSnapshot,
        quotationType
    );

    // merge the quotationType part into quotationsSnapshot
    quotations = mergeQuotationsOfTypeIntoQuotations(
        quotationOfType,
        quotationsSnapshot
    );

    return quotations;
};

/**
 * Merges quotationsOfType (all the basic values or all the plus values) into quotations (all the basic and plus values)
 * @param {*} quotationOfType
 *  A smaller version of quotations, containing only the quotationType data
 *  Sample 1:
 *      {
 *          .potenza
 *              .basic: "2,01 kWp"
 *      },
 *      ...more data
 *  Sample 2:
 *      {
 *          .potenza
 *              .plus: "2,25 kW"
 *      },
 *      ...more data
 * @param {*} quotations
 *  Sample:
 *      {
 *          .potenza
 *              .basic: "2,01 kWp"
 *              .plus: "2,25 kW"
 *      },
 *      ...more data
 */
const mergeQuotationsOfTypeIntoQuotations = (quotationOfType, quotations) => {
    return merge(cloneDeep(quotations), cloneDeep(quotationOfType));
};

/**
 * Extracts only the quotationType data from quotations
 * @param {Object} quotations
 *  Sample:
 *      {
 *          .potenza
 *              .basic: "2,01 kWp"
 *              .plus: "2,25 kW"
 *      },
 *      ...more data
 * @param {Object} quotationType
 *  ['basic'|'plus']
 * @returns {Object}
 *  A smaller version of quotations, containing only the quotationType data
 *  Sample 1:
 *      {
 *          .potenza
 *              .basic: "2,01 kWp"
 *      },
 *      ...more data
 *  Sample 2:
 *      {
 *          .potenza
 *              .plus: "2,25 kW"
 *      },
 *      ...more data
 */
const extractQuotationOfTypeFromQuotations = (quotations, quotationType) => {
    const quotationOfType = {};
    for (let dataId in quotations) {
        const readData = quotations[dataId];
        const writeData = {};
        Object.keys(readData).forEach((key) => {
            if (key.indexOf(quotationType) !== -1) {
                writeData[key] = readData[key];
            }
        });
        quotationOfType[dataId] = writeData;
    }
    return quotationOfType;
};

/**
 * Returns if the inputsSnapshot of the quotations gui matches the best quotations or represents alternative quotations
 * The evaluation of best VS alternative quotations is based on the values of the following data:
 *  - potenza
 *  - capacitaSistemaAccumulo
 * @param {String} quotationType
 *  ['basic'|'plus']
 * @param {Object} bestQuotations
 * @param {Object} inputsSnapshot
 *  .ottimizzatori
 *      .basic {Boolean}
 *      .plus {Boolean}
 *  .potenza
 *      .basic {String}
 *      .plus {String}
 *  .capacitaSistemaAccumulo
 *      .basic {String}
 *      .plus {String}
 */
const inputsSnapshotMatchBestQuotation = (
    quotationType,
    bestQuotations,
    inputsSnapshot = getInputsSnapshot()
) => {
    const inputsSnapshotValues = extractAltquotationsPropertiesFromQuotations(
        inputsSnapshot,
        quotationType
    );
    const bestQuotationsValues = extractAltquotationsPropertiesFromQuotations(
        bestQuotations,
        quotationType
    );

    return isEqual(inputsSnapshotValues, bestQuotationsValues);
};

/**
 * Returns if value is the best quotations value for dataId.quotationType data
 * @param {Mixed} value
 * @param {String} quotationType
 *  ['basic'|'plus']
 * @param {String} dataId
 *  Sample
 *      'potenza'
 * @param {Object} bestQuotations
 *  See /mock-data/quote-service-response.json->quotations for Object structure
 * @returns {Boolean}
 */
const valueIsBestQuotation = (value, quotationType, dataId, bestQuotations) => {
    if (!bestQuotations[dataId] || !bestQuotations[dataId][quotationType]) {
        return false;
    }
    return bestQuotations[dataId][quotationType] === value;
};

/**
 * Renders dataId data row as "best quotation"/"not best quotation"
 * Best quotation rendering involves:
 *  - rendering the element representing the data value
 * @param {String} dataId
 *  Sample
 *      'potenza'
 * @param {Boolean} best
 * @param {HTMLELement} $dataValueElement
 *  The HTMLElement representing the data value itself
 */
const renderDataAsBestQuotation = (dataId, best, $dataValueElement) => {
    const bestCssClassName = 'slc-quotation-best-symbol';

    let bestQuotationTooltipEnabled = false;

    // value

    const $value = $dataValueElement;
    if ($value) {
        if (best) {
            $value.classList.add(bestCssClassName);
            if (!bestQuotationTooltipEnabled) {
                $value.addEventListener(
                    'click',
                    _bestQuotationDataValueOnClick,
                    false
                );
                bestQuotationTooltipEnabled = true;
            }
        } else {
            $value.classList.remove(bestCssClassName);
            $value.removeEventListener(
                'click',
                _bestQuotationDataValueOnClick,
                false
            );
            bestQuotationTooltipEnabled = false;
        }
    }
};

const _bestQuotationDataValueOnClick = (evt) => {
    _bestQuotationTooltipCreate(evt.target);
};

/**
 * Creates the best quotation tooltip for $dataValueElement HTMLElement
 * The tooltip is created only if it has not been already created (only one element is added to the DOM)
 * The tooltip is automatically removed after
 * @param {HTMLElement} $dataValueElement
 * @param {Object} options optional
 *  .message {String} optional
 *  .duration {Number} optional
 */
const _bestQuotationTooltipCreate = ($dataValueElement, options = {}) => {
    if (_bestQuotationTooltipExists($dataValueElement)) {
        return;
    }
    const tooltip = document.createElement('div');
    tooltip.setAttribute('class', 'slc-quotation-best-symbol-tooltip');
    tooltip.innerHTML = options.message || 'Consigliato';
    $dataValueElement.appendChild(tooltip);
    setTimeout(() => {
        $dataValueElement.removeChild(tooltip);
    }, options.duration || 1 * 1000);
};

/**
 * Checks if a best quotation tooltip already exists for $dataValueElement HTMLElement
 * @param {HTMLElement} $dataValueElement
 * @returns {Boolean}
 */
const _bestQuotationTooltipExists = ($dataValueElement) => {
    return (
        $dataValueElement.getElementsByClassName(
            'slc-quotation-best-symbol-tooltip'
        ).length > 0
    );
};

export {
    quotationTypeFromOptionId,
    optionNameFromOptionId,
    dataOptionPathFromOptionId,
    getTotaleBySelection,
    getImportoDetrazioneBySelection,
    getImpiantoBySelection,
    getOptionsSelectionState,
    getHtmlSelectDataFromReduxState,
    getHtmlSelectValueFromReduxState,
    getInputsSnapshot,
    readQuotationFromReduxStateByInputsSnapshot,
    extractQuotationOfTypeFromQuotations,
    valueIsBestQuotation,
    renderDataAsBestQuotation,
};
