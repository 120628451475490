import React from 'react';

const Step2Infos = (props) => {
    return (
        <div className="slc-wizard-infos-step slc-wizard-infos-step-2">
        Indica l’orientamento prevalente del tuo tetto anche se non è esattamente preciso.<br />
        La falda migliore dove installare l’impianto è quella con l'orientamento a Sud. Anche orientamenti a Sud-Est, a Sud-Ovest, a Est o ad Ovest consentono di raccogliere una alta percentuale di radiazione solare.
        </div>
    );
}

export default Step2Infos;