/**
 * PageWizard Components
 *
 * @state
 *      responsive
 */

import React from 'react';

import PageLayout from '../layout/PageLayout';
import Header from '../Header';
import Wizard from '../wizard/Wizard';
import WizardInfos from '../wizard/infos/WizardInfos';
import ConfigurationState from '../ConfigurationState';
import Footer from '../Footer';

import './Page.css';
import './PageWizard.css';

const PageWizard = () => {
    return (
        <PageLayout
            pageId="wizard"
            header={<Header />}
            body={<Body />}
            footer={<Footer />}
        />
    );
};

const Body = () => {
    const openInfos = function () {
        document
            .querySelector('.slc-page-wizard-infos')
            .classList.add('slc-page-wizard-infos--opened');
        document.querySelector('.slc-wizard-infos-btn-close').style.display =
            'flex';
    };

    const closeInfos = function () {
        document
            .querySelector('.slc-page-wizard-infos')
            .classList.remove('slc-page-wizard-infos--opened');
        document.querySelector('.slc-wizard-infos-btn-close').style.display =
            'none';
    };

    return (
        <>
            <div className="col s12 l3 slc-page-wizard-infos">
                <WizardInfos
                    openInfosFn={openInfos}
                    closeInfosFn={closeInfos}
                />
            </div>
            <div className="col s12 l6 slc-page-wizard-wizard">
                <Wizard />
            </div>
            <div className="col s12 l3 slc-page-wizard-configuration-state">
                <ConfigurationState />
            </div>
        </>
    );
};

export default PageWizard;
