import React from 'react';

import useHeaderTitle from '../hooks/useHeaderTitle';

const HeaderTitle = ({ title }) => {

    const { hasTitle } = useHeaderTitle(title);

    return (hasTitle()) ?
        <div className='slc-header-title slc-font-title'>{ title }</div> :
        null ;
};

export default HeaderTitle;