/**
 * Step7 Component: electricityConsumption
 */

import React, { Component, Fragment } from 'react';

import { getValueGuiFn } from '../../../conf/conf';

import appStore from '../../../redux/store/appStore';
import { updateGui, updateConfiguration } from '../../../redux/actions/actions';

import Step from './Step';

import { nextPermissionDeniedGuiFeedback } from '../../../helpers/wizard-helper';

import './Step5.css';

import { getItem } from '../../../helpers/object-helper';

// Menu related imports

import MenuSelector from '../../../helpers/MenuSelector';

import option1personaImg from '../../../images/step7/1persona.png';
import option1personaImgSelected from '../../../images/step7/1persona_selected.png';

import option2personeImg from '../../../images/step7/2persone.png';
import option2personeImgSelected from '../../../images/step7/2persone_selected.png';

import option3personeImg from '../../../images/step7/3persone.png';
import option3personeImgSelected from '../../../images/step7/3persone_selected.png';

import option4personeImg from '../../../images/step7/4persone.png';
import option4personeImgSelected from '../../../images/step7/4persone_selected.png';

import optionPlus4personeImg from '../../../images/step7/plus4persone.png';
import optionPlus4personeImgSelected from '../../../images/step7/plus4persone_selected.png';

const images = {
    option1persona: [option1personaImg, option1personaImgSelected],
    option2persone: [option2personeImg, option2personeImgSelected],
    option3persone: [option3personeImg, option3personeImgSelected],
    option4persone: [option4personeImg, option4personeImgSelected],
    optionPlus4persone: [optionPlus4personeImg, optionPlus4personeImgSelected]
};

const optionsToCost = {
    '1persona': 80,
    '2persone': 120,
    '3persone': 160,
    '4persone': 200,
    'Plus4persone':240
};

const stepNum = 5;
const editingDataId = 'configuration.electricityConsumption';
const editingDataIdPersone = 'configuration.electricityConsumptionPersone';
const editingDataIdCost = 'configuration.electricityConsumptionCost';
const options = ['1persona', '2persone', '3persone', '4persone', 'Plus4persone'];
const selectedClass = 'selected';

class Step5 extends Component {

    constructor(props) {
        super(props);
        this.editingDataId = editingDataId;
        this.isValidated = this.isValidated.bind(this);
        this.onClickOption = this.onClickOption.bind(this);
        this.onCostInput = this.onCostInput.bind(this);
        this.onConsumptionInput = this.onConsumptionInput.bind(this);
    }

    componentDidMount() {

        appStore.dispatch(updateGui({
            currentEditingData: editingDataId
        }));

        // setup persone menu (autoselects item, if any selected already)
        const menuSelector = new MenuSelector({
            itemsElements: document.querySelectorAll('.slc-electricityConsumption-option'),
            selectedElement: this.getSelectedOptionFromStore(),
            guiHiliteFn: this.optionGuiHilite.bind(this),
            guiDehiliteFn: this.optionGuiDehilite.bind(this),
            onItemClickFn: (itemElement) => {
                this.updateStorePersone(itemElement);
            }
        });

        // autofill cost if already set in application store
        this.updateCostGuiFromStore();

        // autofill consumption if already set in application store
        this.updateConsumptionGuiFromStore();

    }

    isValidated(showWarnMessage) {
        const data = getItem(editingDataId, appStore.getState());
        const valid = (data !== null);
        if (!valid && showWarnMessage === undefined) {
            nextPermissionDeniedGuiFeedback(this, stepNum, true);
        }
        return (data !== null);
    }

    /**
     * Updates electricityConsumption in application store
     * @param {Number} consumption
     */
    updateStore(consumption) {
        appStore.dispatch(updateConfiguration({
            electricityConsumption: consumption
        }));
    }

    /**
     * Updates electricityConsumptionPersone in application store
     * @param {HTMLElement} itemElement
     */
    updateStorePersone(itemElement) {
        appStore.dispatch(updateConfiguration({
            electricityConsumptionPersone: this.getOptionElementValue(itemElement)
        }));
    }

    /**
     * Updates electricityConsumptionCost in application store
     * @param {Number} cost
     */
    updateStoreCost(cost) {
        appStore.dispatch(updateConfiguration({
            electricityConsumptionCost: cost
        }));
    }

    /**
     * Event listener for persone menu options click
     * @param {Event} evt
     */
    onClickOption(evt) {
        const value = evt.currentTarget.getAttribute('data-value');
        const cost = optionsToCost[value];
        this.updateCostValue(cost);
        this.updateConsumptionValue();
    }

    /**
     * Event listener for cost input event
     * @param {Event} evt
     */
    onCostInput(evt) {
        let cost = parseInt(evt.target.value);
        if (isNaN(cost)) {
            cost = null;
        }
        this.updateStoreCost(cost);
        this.updateConsumptionValue();
    }

    /**
     * Event listener for consumption input event
     * @param {Event} evt
     */
    onConsumptionInput(evt) {
        let consumption = parseInt(evt.target.value);
        if (isNaN(consumption)) {
            consumption = null;
        }
        this.updateStore(consumption);
    }

    /**
     * Updates cost value
     *  - in gui
     *  - in application store
     * @param {Number} icost 
     */
    updateCostValue(icost = null) {
        this.updateCostGui(icost);
        this.updateStoreCost(icost);
    }

    /**
     * Updates cost gui
     * @param {Number} icost 
     */
    updateCostGui(icost) {
        if (icost) {
            document.querySelector('.slc-electricityConsumption-question-cost input').value = icost;
        }
    }

    /**
     * Updates cost gui with application store value
     */
    updateCostGuiFromStore() {
        const cost = getItem('configuration.electricityConsumptionCost', appStore.getState());
        this.updateCostGui(cost);
    }

    updateConsumptionValue(icost = null) {
        const cost = (icost) ? icost : parseInt(document.querySelector('.slc-electricityConsumption-question-cost input').value);
        const consumption = (!isNaN(cost)) ? cost * 25 : '';
        //document.querySelector('.slc-electricityConsumption-question-consumption input').value = consumption;
        this.updateConsumptionGui(consumption);
        this.updateStore(consumption);
    }

    /**
     * Updates consumption gui
     * @param {Number} consumption 
     */
    updateConsumptionGui(consumption) {
        if (consumption) {
            document.querySelector('.slc-electricityConsumption-question-consumption input').value = consumption;
        }
    }

    /**
     * Updates consumption gui with application store value
     */
    updateConsumptionGuiFromStore() {
        const consumption = getItem('configuration.electricityConsumption', appStore.getState());
        this.updateConsumptionGui(consumption);
    }

    /**
     * Returns the optionElement value, reading it from the 'data-value' element attribute
     */
    getOptionElementValue(optionElement) {
        return optionElement.getAttribute('data-value');
    }

    /**
     * Reads from the store the value of the piece of data related to this step ('configuration.roofInclination')
     *  a. If a value is found, the option HTMLElement representing that value is returned
     *  b. Otherwise, null is returned
     * @return {HTMLElement|null}
     */
    getSelectedOptionFromStore() {
        const persone = getItem(editingDataIdPersone, appStore.getState()); 
        return (persone !== null) ?
            document.querySelector('.slc-electricityConsumption-option-' + persone) :
            null;
    }

    /**
     * Hilites optionElement
     * Hilite is accomplished through 2 updates:
     *  1. Option image src
     *  2. Option label bg color (through the 'selected' class usage)
     * @param {HTMLElement} optionElement 
     */
    optionGuiHilite(optionElement) {
        // get necessary data
        const optionElementValue = this.getOptionElementValue(optionElement);
        const imageElement = optionElement.childNodes[0];
        // hilite label
        optionElement.classList.add(selectedClass);
        // hilite image
        imageElement.src = images['option' + optionElementValue][1];
    }

    /**
     * Dehilites optionElement
     * Dehilite is accomplished through 2 updates:
     *  1. Option image src
     *  2. Option label bg color (through the 'selected' class usage)
     * @param {HTMLElement} optionElement 
     */
    optionGuiDehilite(optionElement) {
        // get necessary data
        const optionElementValue = this.getOptionElementValue(optionElement);
        const imageElement = optionElement.childNodes[0];
        // dehilite label
        optionElement.classList.remove(selectedClass);
        // dehilite image
        imageElement.src = images['option' + optionElementValue][0];
    }

    /**
     * Returns the JSX body for this Step<N> Component
     * The body is passed as a prop to the Step Component, which is actually rendered
     */
    body() {
        return (
            <Fragment>
                <div className="slc-electricityConsumption">
                    <div className="slc-electricityConsumption-options">
                    {this.bodyGetOptionsHtml()}
                    </div>
                    {this.bodyGetQuestions()}
                </div>
            </Fragment>
        );
    }

    /**
    body() {
        return (
            <Fragment>
                <div className="slc-electricityConsumption">
                    <div className="slc-electricityConsumption-question slc-electricityConsumption-question-people">Quante persone vivono in casa?</div>
                    <div className="slc-electricityConsumption-options">
                    {this.bodyGetOptionsHtml()}
                    </div>
                    {this.bodyGetQuestions()}
                </div>
            </Fragment>
        );
    }
     */

    bodyGetQuestions() {
        return (
            <div className="slc-electricityConsumption-questions">
                <div className="slc-form-input-text-widget slc-form-input-text-widget--with-infos slc-electricityConsumption-question slc-electricityConsumption-question-cost">
                    <div className="slc-form-input-text-widget__label">
                    Qual è l’importo della tua ultima bolletta elettrica?
                    </div>
                    <div className="slc-form-input-text-widget__input-container">
                        <input type="text" className="slc-form-input-text-widget__input" onInput={this.onCostInput}></input>
                        <div className="slc-form-input-text-widget__input-measure-unit">&euro;</div>
                        <div className="slc-form-input-text-widget__input-infos">(bimestrale)</div>
                    </div>
                </div>
                <div className="slc-form-input-text-widget slc-electricityConsumption-question slc-electricityConsumption-question-consumption">
                    <div className="slc-form-input-text-widget__label">
                    Quanti kWh consumi all’anno?
                    </div>
                    <div className="slc-form-input-text-widget__input-container">
                        <input type="text" className="slc-form-input-text-widget__input" onInput={this.onConsumptionInput}></input>
                        <div className="slc-form-input-text-widget__input-measure-unit">kWh/anno</div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Returns the JSX for all the options
     * @return JSX
     */
    bodyGetOptionsHtml() {
        return options.map((option) => {
            return this.bodyGetOptionHtml(option);
        });
    }

    /**
     * Returns the JSX for the optionValue cardinal point
     * @param {Number} optionValue
     * @return JSX
     */
    bodyGetOptionHtml(optionValue) {
        return (
            <div
                key={optionValue}
                className={'slc-electricityConsumption-option slc-electricityConsumption-option-' + optionValue}
                data-value={optionValue}
                onClick={this.onClickOption}>
                <img
                    src={images['option' + optionValue][0]} className="slc-electricityConsumption-option-image" alt={optionValue} />
                <div
                    className="slc-electricityConsumption-option-label slc-circle-fit-to-content slc-option-label">{getValueGuiFn(editingDataId)(optionValue)}
                </div>
            </div>
        );
    }

    render() {
        return <Step stepComponent={this} stepNum={stepNum} stepBody={this.body()} />;
    }
    
}

export default Step5;