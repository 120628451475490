import isEqual from 'lodash/isEqual';
import { getConfItem } from '../conf/conf';
import appStore from '../redux/store/appStore';
import {
    getBatteryCodeByLabel,
    getBatteriesForPower,
    batteryBelongsToPower,
    getBestBattery,
    getBestBatteryForPower
} from './batteries-store-helper';

export const getAltQuotationsAll = () => {
    return appStore.getState().altquotations;
};

/**
 * Returns the alternative quotations determined by quotationType and inputsSnapshot
 * @param {String} quotationType 
 * ['basic'|'plus']
 * @param {Object} inputsSnapshot
 *  .accumulatoreProposto
 *      .basic {Boolean}
 *      .plus {Boolean}
 *  .ottimizzatori
 *      .plus {Boolean}
 *  .numeroPannelli
 *      .basic {Number}
 *      .plus {Number}
 *  .capacitaSistemaAccumulo
 *      .basic {String}
 *      .plus {String}
 * @param {Object} altQuotations 
 */
export const getAltQuotations = (quotationType, inputsSnapshot, altQuotations) => {

    altQuotations = altQuotations || getAltQuotationsAll();

    const potenza = inputsSnapshot.potenza[quotationType];
    const capacitaSistemaAccumuloLabel = inputsSnapshot.capacitaSistemaAccumulo[quotationType];

    // no battery not available in current snapshot -> altquotations are read from the power altquotations level (~ 'basic' quotationType)
    if (capacitaSistemaAccumuloLabel === getConfItem('altquotations.featureNotAvailableValue')) {

        return altQuotations[quotationType].quotations[potenza];
    
    // battery available in current snapshot -> altquotations are read form the power+battery level (~ 'plus' quotationType)
    } else {

        let capacitaSistemaAccumulo;
        
        // check if the current snapshot battery is ok (is a possible battery) for the current snapshot power
        const inputsSnapshotBatteryOk = batteryBelongsToPower(quotationType, capacitaSistemaAccumuloLabel, potenza);
            
        // battery is ok -> use the current snapshot battery
        if (inputsSnapshotBatteryOk) {
            capacitaSistemaAccumulo = getBatteryCodeByLabel(capacitaSistemaAccumuloLabel, quotationType);
        // battery is not ok (it is not a possible battery for the current power) -> get the best battery for the current snapshot power
        } else {
            const bestBatteryForPower = getBestBatteryForPower(quotationType, potenza);
            capacitaSistemaAccumulo = bestBatteryForPower.code;
        }

        return altQuotations[quotationType].quotations[potenza][capacitaSistemaAccumulo];
    }
    
    /*
    // check if the current snapshot battery is ok (is a possible battery) for the current snapshot power
    const inputsSnapshotBatteryOk = batteryBelongsToPower(quotationType, capacitaSistemaAccumuloLabel, potenza);
    
    // battery is ok -> use the current snapshot battery
    if (inputsSnapshotBatteryOk) {
        capacitaSistemaAccumulo = getBatteryCodeByLabel(capacitaSistemaAccumuloLabel, quotationType);
    // battery is not ok (it is not a possible battery for the current power) -> get the best battery for the current snapshot power
    } else {
        const bestBatteryForPower = getBestBatteryForPower(quotationType, potenza);
        capacitaSistemaAccumulo = bestBatteryForPower.code;
    }

    return (quotationType === 'plus')
        ? altQuotations[quotationType].quotations[potenza][capacitaSistemaAccumulo]
        : altQuotations[quotationType].quotations[potenza];
    */
        
};

/**
export const getAltQuotations = (quotationType, inputsSnapshot, altQuotations) => {
    altQuotations = altQuotations || getAltQuotationsAll();
    const potenza = inputsSnapshot.potenza[quotationType];
    const capacitaSistemaAccumulo = getBatteryCodeByLabel(inputsSnapshot.capacitaSistemaAccumulo[quotationType], quotationType);
    return (quotationType === 'plus')
        ? altQuotations[quotationType].quotations[potenza][capacitaSistemaAccumulo]
        : altQuotations[quotationType].quotations[potenza];
};
 */

/**
 * Analyzes Redux state quotations snapshot
 * If snapshot contains alternative quotations, returns the alternative quotations' identification properties
 * If snapshot contains the best quotations, no alternative quotations is selected, so returns null
 * @param {String} quotationType 
 * ['basic'|'plus']
 * @param {Object} snapshotQuotations 
 * @param {Object} bestQuotations 
 * @returns {Object|null}
 */
export const altQuotationsFromSnapshot = (quotationType, snapshotQuotations, bestQuotations) => {

    let result = null;

    const snapshotValues = extractAltquotationsPropertiesFromQuotations(snapshotQuotations, quotationType);
    const bestQuotationsValues = extractAltquotationsPropertiesFromQuotations(bestQuotations, quotationType);

    if (!isEqual(snapshotValues, bestQuotationsValues)) {
        result = snapshotValues;
    }

    return result;

};

/**
 * Extracts a properties subset from quotations
 * The properties subset contains only the alternative quotations' identification properties
 * Alternative quotations, in fact, are determined by a specific quotations' properties list
 * The specific quotations' properties list is defined in conf/conf.js
 * @param {Object} quotations 
 * @param {String} quotationType 
 *  ['basic', 'plus']
 * @returns {Object}
 *  .potenza {String}
 *  .capacitaSistemaAccumulo {String}
 */
export const extractAltquotationsPropertiesFromQuotations = (quotations, quotationType) => {
    const altquotationsDataIds = getConfItem('altquotations.identificationDataIds');
    return altquotationsDataIds.reduce((acc, dataId) => {
        let dataValue = quotations[dataId][quotationType];
        acc[dataId] = dataValue;
        return acc;
    }, {});
};

/**
export const extractAltquotationsPropertiesFromQuotations = (quotations, quotationType) => {
    const altquotationsDataIds = getConfItem('altquotations.identificationDataIds');
    return altquotationsDataIds.reduce((acc, dataId) => {
        let dataValue = quotations[dataId][quotationType];
        if (dataId === 'numeroPannelli') {
            dataValue = parseInt(dataValue);
        }
        acc[dataId] = dataValue;
        return acc;
    }, {});
};
 */

/**
 * Returns the maximum number of panels
 * @param {String} quotationType 
 *  ['basic'|'plus']
 */
export const getMaxNumeroPannelli = quotationType => {
    const altQuotations = getAltQuotationsAll();
    if (!altQuotations) {
        return '';
    }
    const numeroPannelliAvailable = altQuotations[quotationType].npavail;
    return numeroPannelliAvailable[numeroPannelliAvailable.length - 1];
};

/**
 * Returns the maximum installable power
 * @param {String} quotationType 
 *  ['basic'|'plus']
 */
 export const getMaxPotenzaInstallabile = quotationType => {
    const altQuotations = getAltQuotationsAll();
    if (!altQuotations) {
        return '';
    }
    const potenzaInstallabileAvailable = altQuotations[quotationType].pwravail;
    return potenzaInstallabileAvailable[potenzaInstallabileAvailable.length - 1];
};
