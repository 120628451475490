import React from 'react';

const Step4Infos = (props) => {
    return (
        <div className="slc-wizard-infos-step slc-wizard-infos-step-4">
        Seleziona il n° di piani FUORI TERRA della tua abitazione.<br />Questa informazione è utile per capire quanto è accessibile il tuo tetto.<br />Indica 1 PIANO se la tua abitazione si sviluppa su un singolo piano.<br />Diversamente indica da quanti piani fuori terra è costituito l’immobile.
        </div>
    );
}

export default Step4Infos;