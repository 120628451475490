/**
 * currency-helper module
 * 
 *  Provides variuos value formatting functions, not only for currency, but also for energy values
 */

const defaultLocale = 'it-IT';
const defaultOptions = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
};

/**
 * Formats value to a currency with Intl.NumberFormat.format
 * @return {String}
 */
const format = (value, locale = defaultLocale, options = defaultOptions) => {
    return new Intl.NumberFormat(locale, options).format(value);
};

/**
 * Turns priceString String into a Float
 * @param {String} priceString
 *  Sample
 *      '7.753,91'
 * @return {Float}
 *  Sample
 *      7753.91
 */
const priceStringToFloat = (priceString) => {
    let prepare = priceString.replace('.', '').replace(',', '.');
    let f = parseFloat(prepare);
    return f;
};

/**
 * Formats priceString String as defined by defaultOptions (EUR, 2 fraction digits)
 * @param {String} priceString
 *  Samples
 *      '7.753,91'
 *      '7.753,9'
 * @return {Float}
 *  Samples
 *      '7.753,91 €'
 *      '7.753,90 €'
 */
const priceStringFormat = (priceString) => {
    return format(priceStringToFloat(priceString));
};

/**
 * Formats floatString String to a 2 decimal number String representation
 * @param {String} floatString 
 *  Samples
 *      '7,255 kWh'
 *      '7,2 kWh'
 * @param {String} measureUnit
 *  Sample
 *      'kWh'
 *  @return {String}
 *      '7.25'
 *      '7.20'
 */
const floatStringFormat = (floatString, measureUnit) => {
    measureUnit = (measureUnit) ? ' ' + measureUnit : '' ;
    return parseFloat(floatString.replace(',', '.')).toFixed(2).replace('.', ',') + measureUnit;
};

/**
 * Formats inputString representing the produzioneAnnualeGarantitaValue
 * Adds the decimals, if missing, to the numeric part of the string
 * @param {String} floatString 
 *  Sample
 *      '6.000/kWh annuo'
 *      '6.000,55/kWh annuo'
 *  @return {String}
 *      '6.000,00/kWh annuo'
 *      '6.000,55/kWh annuo'
 */
const formatProduzioneAnnualeGarantita = (inputString) => {
    if (inputString.indexOf(',') !== -1) {
        return inputString;
    } else {
        const src = /([\d\.]+)(,[\d]*)?/;
        const rep = '$1,00';
        return inputString.replace(src, rep);
    }
};

/**
 * Updates the float value part of originalValueString, multiplying it by multiplier
 * @param {String} originalValueString 
 *  Sample
 *      '6.000/kWh annuo'
 * @param {Float} multiplier 
 *  Sample
 *      1.05
 * @return {String}
 *  Sample
 *      '6.300/kWh annuo'
 */
const produzioneAnnualeGarantitaMultiply = (originalValueString, multiplier) => {
    const originalValueFloat = priceStringToFloat(originalValueString);
    const newValueFloat = originalValueFloat * multiplier;
    const newValueString = new Intl.NumberFormat('it-IT', {maximumFractionDigits: 2}).format(newValueFloat) + '/kWh';
    return newValueString;
};

export {
    format,
    priceStringToFloat,
    priceStringFormat,
    floatStringFormat,
    formatProduzioneAnnualeGarantita,
    produzioneAnnualeGarantitaMultiply
}