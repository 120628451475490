/**
 * ConfigurationState Component
 * Redux connected
 * Renders the user defined configuration choices
 * 
 * @prop {Object} user
 *  Maps to state.user
 * @prop {Object} configuration
 *  Maps to state.configuration
 */

import React from 'react';
import { connect } from "react-redux";

import ConfigurationStateItem from './ConfigurationStateItem';

import {
    getConfItem,
} from '../conf/conf';

import './ConfigurationState.css';

const mapStateToProps = state => {
    return {
        gui: state.gui,
        user: state.user,
        configuration: state.configuration,
        map: state.map
    };
};

const ConfigurationState = (props) => {

    /**
     * Returns collection rendered items
     * Renders only items with a !== null value
     * @param {String} category
     *  Can be any among the following redux state categories:
     *      'user'
     *      'configuration'
     * @return JSX
     */
    const renderItems = (category, exclude = []) => {

        // fetch props to be excluded from config, if any
        const guiConf = getConfItem('gui.configurationState');
        if (guiConf[category] && guiConf[category].exclude) {
            exclude = guiConf[category].exclude;
        }

        // fetch items collection
        const collection = props[category];

        // for each item
        return Object.keys(collection).map((prop, i) => {
            let value = collection[prop];
            // return null if item/prop's value is not set
            if (!value) {
                return null;
            // return null if item/prop is to be excluded
            } else if (exclude.includes(prop)) {
                return null;
            // return ConfigurationStateItem for item
            } else {
                const confItemPath = category + '.' + prop;
                return <ConfigurationStateItem key={confItemPath} itemPath={confItemPath} itemName={prop} itemValue={value} />
            }
        });
        
    };

    /**
     * Renders google maps with the user drawn polygon, if any
     * Otherwise, renders nothing
     */
    const renderMapImage = () => {
        if (props.map.staticImageUrl && props.map.data.polygon) {
            return (
                <div className="slc-configuration-state__map-image">
                    <img src={props.map.staticImageUrl} alt={props.user.address} />
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className='slc-configuration-state'>
            <div className="slc-configuration-state__title slc-font-title">La tua configurazione</div>
            {renderMapImage()}
            {renderItems('user')}
            {renderItems('configuration')}
        </div>
    );
};

export default connect(mapStateToProps)(ConfigurationState);