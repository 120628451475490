import React from "react";

const defaults = {
  className: "html-checkbox",
  checked: false,
  onChange: evt => {}
};

const HtmlCheckbox = ({
    className,
    checked,
    onChange
}) => {
  
    className = className || defaults.className;
  checked = checked !== undefined ? checked : defaults.checked;
  onChange = onChange || defaults.onChange;

  return (
    <input
        type="checkbox"
        className={className}
        defaultChecked={checked}
        onChange={ onChange } />
  );
  
};

export default HtmlCheckbox;
