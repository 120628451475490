/**
 * Step2 Component: roofOrientation
 */

import React, { Component, Fragment } from 'react';

import appStore from '../../../redux/store/appStore';
import { updateGui, updateConfiguration } from '../../../redux/actions/actions';

import Step from './Step';

import { nextPermissionDeniedGuiFeedback } from '../../../helpers/wizard-helper';

import { mapImageUrl } from '../../../helpers/google-map-helper';

import { getItem } from '../../../helpers/object-helper';

import './Step2.css';

import imgBussola from '../../../images/step2/bussola.png';
import imgNorth from '../../../images/step2/N.png';
import imgNorthEast from '../../../images/step2/NE.png';
import imgEast from '../../../images/step2/E.png';
import imgSouthEast from '../../../images/step2/SE.png';
import imgSouth from '../../../images/step2/S.png';
import imgSouthWest from '../../../images/step2/SW.png';
import imgWest from '../../../images/step2/W.png';
import imgNorthWest from '../../../images/step2/NW.png';

const stepNum = 2;
const editingDataId = 'configuration.roofOrientation';
const cardinalPoints = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
const cardinalPointsImages = {
    'N': imgNorth,
    'NE': imgNorthEast,
    'E': imgEast,
    'SE': imgSouthEast,
    'S': imgSouth,
    'SW': imgSouthWest,
    'W': imgWest,
    'NW': imgNorthWest
};
const cardinalPointSelectedClass = 'slc-cardinal-point-selected';

class Step2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardinalPointSelected: null,
            mapStaticImageUrl: null
        };
        this.onCardinalPointEnter = this.onCardinalPointEnter.bind(this);
        this.onCardinalPointLeave = this.onCardinalPointLeave.bind(this);
        this.onCardinalPointClick = this.onCardinalPointClick.bind(this);
        this.editingDataId = editingDataId;
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {
        // set local state mapStaticImageUrl and address from store
        const appState = appStore.getState();
        const map = getItem('map.refs.map', appState);
        const polygon = getItem('map.refs.polygon', appState);
        const responsiveState = getItem('gui.responsive', appState);
        const mapUrl = mapImageUrl(
            map,
            polygon,
            {
                size: this.mapImageViewportGetSizeToUrlValue(),
                // in tablet gui, image needs a 2 scale, othwerwise the image viewport would be wider than the image, resulting in a nasty graphic effect
                scale: (responsiveState === 'tablet') ? 2 : 1
            }
        );
        this.setState((state, props) => ({
            mapStaticImageUrl: mapUrl,
            address:  getItem('user.address', appState)
        }));

        // update store gui.currentEditingData
        appStore.dispatch(updateGui({
            currentEditingData: editingDataId
        }));

        // select gui cardinal point from store
        const roofOrientation = appStore.getState().configuration.roofOrientation;
        if (roofOrientation) {
            this.cardinalPointSelectByRoofOrientation(roofOrientation);
        }

    }

    isValidated(showWarnMessage) {
        const data = getItem(editingDataId, appStore.getState());
        const valid = (data !== null);
        if (!valid && showWarnMessage === undefined) {
            nextPermissionDeniedGuiFeedback(this, stepNum, true);
        }
        return valid;
    }

    /**
    isValidated() {
        const data = getItem(editingDataId, appStore.getState());
        return (data !== null);
    } 
     */

    /**
     * MouseEnter event listener on cardinal points html elements
     *  Hilites the element, except for the selected one
     * @param {Event} evt 
     */
    onCardinalPointEnter(evt) {
        const cardinalPointElement = evt.target;
        if (cardinalPointElement !== this.state.cardinalPointSelected) {
            this.cardinalPointHilite(cardinalPointElement);
        }
    }

    /**
     * MouseLeave event listener on cardinal points html elements
     *  Dehilites the element, except for the selected one
     * @param {Event} evt 
     */
    onCardinalPointLeave (evt) {
        const cardinalPointElement = evt.target;
        if (cardinalPointElement !== this.state.cardinalPointSelected) {
            this.cardinalPointDehilite(cardinalPointElement);
        }
    }

    /**
     * Click event listener on cardinal points html elements
     *  1. Selects the element
     *  2. Dispatches the roofOrientation update redux action
     * @param {Event} evt 
     */
    onCardinalPointClick (evt) {
        const cardinalPointElement = evt.target;
        this.cardinalPointSelect(cardinalPointElement);
        this.cardinalPointImageSelect(cardinalPointElement.innerHTML);
        appStore.dispatch(updateConfiguration({
            roofOrientation: cardinalPointElement.innerHTML
        }));
    }

    /**
     * Selects the cardinal point html element identified by roofOrientation
     * @param {String} roofOrientation
     *  Range
     *      ['N'|'NE'|'E'|'SE'|'S'|'SW'|'W'|'NW'|]
     */
    cardinalPointSelectByRoofOrientation (roofOrientation) {
        const htmlElement = document.querySelector('.slc-cardinal-point-' + roofOrientation);
        this.cardinalPointSelect(htmlElement);
        this.cardinalPointImageSelect(roofOrientation);
    }

    /**
     * Selects htmlElement (deselecting the previous selected one, if any)
     * @param {HTMLElement} htmlElement
     */
    cardinalPointSelect (htmlElement) {
        if (this.state.cardinalPointSelected !== null) {
            this.state.cardinalPointSelected.classList.remove(cardinalPointSelectedClass);
        }
        htmlElement.classList.add(cardinalPointSelectedClass);
        this.setState((state, props) => ({
            cardinalPointSelected: htmlElement
        }));
    }

    /**
     * Hilites htmlElement
     * @param {HTMLElement} htmlElement 
     */
    cardinalPointHilite (htmlElement) {
        htmlElement.classList.add(cardinalPointSelectedClass);
    }

    /**
     * Dehilites htmlElement
     * @param {HTMLElement} htmlElement 
     */
    cardinalPointDehilite(htmlElement) {
        htmlElement.classList.remove(cardinalPointSelectedClass);
    }
    
    cardinalPointImageGetDom() {
        return document.querySelector('.slc-roofOrientation-chooser-image');
    }

    cardinalPointImageSelect(cardinalPointValue) {
        this.cardinalPointImageGetDom().setAttribute('src', cardinalPointsImages[cardinalPointValue]);
    }

    mapImageViewportGetDom() {
        return document.querySelector('.slc-roofOrientation-image');
    }

    mapImageViewportGetSize() {
        const element = this.mapImageViewportGetDom();
        return {
            width: element.clientWidth,
            height: element.clientHeight
        };
    }

    mapImageViewportGetSizeToUrlValue() {
        const size = this.mapImageViewportGetSize();
        return size.width + 'x' + size.height;
    }

    /**
     * Returns the JSX body for this Step<N> Component
     * The body is passed as a prop to the Step Component, which is actually rendered
     */
    body() {
        return (
            <Fragment>
                <div className="slc-roofOrientation">
                    <div className="slc-roofOrientation-image">
                        <img src={this.state.mapStaticImageUrl} alt={this.state.address} />
                    </div>
                    <div className="slc-roofOrientation-chooser">
                        <div className="slc-roofOrientation-chooser-image-container">
                            <img className="slc-roofOrientation-chooser-image" src={imgBussola} alt="" />
                        </div>
                        {this.bodyGetCardinalPointsHtml()}
                    </div>
                </div>
            </Fragment>
        );
    }
    
    /**
     * Returns the JSX for all the cardinal points
     * @return JSX
     */
    bodyGetCardinalPointsHtml() {
        return cardinalPoints.map((cardinalPoint) => {
            return this.bodyGetCardinalPointHtml(cardinalPoint);
        });
    }

    /**
     * Returns the JSX for the cardinalPointValue cardinal point
     * @param {String} cardinalPointValue
     * @return JSX
     */
    bodyGetCardinalPointHtml(cardinalPointValue) {
        return (
            <div
                key={cardinalPointValue}
                className={'slc-cardinal-point slc-cardinal-point-' + cardinalPointValue}
                onMouseEnter={this.onCardinalPointEnter}
                onMouseLeave={this.onCardinalPointLeave}
                onClick={this.onCardinalPointClick}>{cardinalPointValue}</div>
        );
    }

    render() {
        return <Step stepComponent={this} stepNum={stepNum} stepBody={this.body()} />;
    }
    
}

export default Step2;