import { getConfItem } from '../conf/conf';

const googleMaps = window.google.maps;
const googleMapsApiKey = getConfItem('gui.map._k');
var imageGeneratorUrl = getConfItem('gui.map.mapStaticImage.generatorUrl');

var map;
var drawingManager;
var selectedShape;
var onInitializedCallback;
var updateShapeAreaCallback;
var onMapCenterChangedCallback;
var onMapZoomChangedCallback;

/**                                                                                       Defaults
 * ===============================================================================================
 */

const mapOptionsDefault = {
    zoom: 16,
    center: new googleMaps.LatLng(52.25097, 20.97114),
    mapTypeId: googleMaps.MapTypeId.SATELLITE,
    disableDefaultUI: true,
    zoomControl: true
};

const polygonOptionsDefault = {
    strokeWeight: 0,
    fillOpacity: 0.75,
    fillColor: '#f7bd01',
    editable: true,
    draggable: true
};

const drawingManagerOptionsDefault = {
    drawingMode: googleMaps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    polygonOptions: polygonOptionsDefault,
    map: map
};

/**                                                               Polygon.getBoundingBox extension
 * ===============================================================================================
 */

/**
 * Returns a LatLngBounds object with all a Polygon points
 * This can be used to get a Polygon center:
 *  polygonInstance.getBoundingBox().getCenter()
 * @return {LatLngBounds}
 */
googleMaps.Polygon.prototype.getBoundingBox = function() {
    var bounds = new googleMaps.LatLngBounds();
    this.getPath().forEach(function(element,index) {
        bounds.extend(element)
    });
    return(bounds);
};

/**
 * Returns a LatLng object representing a Polygon center
 * @return {LatLng}
 */
googleMaps.Polygon.prototype.getCenter = function() {
    return this.getBoundingBox().getCenter();
};

/**                                                                                     Initialize
 * ===============================================================================================
 */

/**
 * Initializes map
 * @param {Object} options
 *  .address {String}
 *  .mapOptions {Object}
 *  .drawingManagerOptions {Object}
 *  .polygonOptions {Object}
 *  .polygonShape ???
 *  .updateShapeAreaCallback {Function}
 */
const initialize = (options) => {
    mapHide();
    mapCreate(options.mapOptions);
    //polygonCreate(options); TODO: does not wok, abandoned for lack of time
    //mapCenter(options, map); TODO: activate if polygonCreate(options) implementation is completed
    mapGeocode(options.address || '', map);
    drawingManagerCreate(options.drawingManagerOptions, options.polygonOptions);
    registerCallbacks(options);
    assignEvents();
    onInitializedCallback(map);
};

/**
 * Creates and returns the google.maps.Map map object with a mix of default and arguments options
 * @param {Object} mapOptions
 *  https://google-developers.appspot.com/maps/documentation/javascript/reference/map#MapOptions
 * @return {google.maps.Map}
 */
const mapCreate = (mapOptions = {}) => {
    const options = {...mapOptionsDefault, ...mapOptions};
    //console.log('CREATING MAP WITH OPTIONS', options);
    map = new googleMaps.Map(domGetMap(), options);
    // avoid the angle of incidence of the map to switch to 45° for high levels of zoom (which would cause a misalignment between the Map and the Map Static Image polygon position)
    map.setTilt(0);
    return map;
};

/**
 * TODO: does not wok, abandoned for lack of time
 * Should
 *  1. Render the polygon described in options
 *  2. Select it for editing (without conflicitng with drawing manager)
 * @param {Object} options 
 */
const polygonCreate = (options) => {
    const polygonCoords = options.polygonCoords;
    const polygonOptions = options.polygonOptions;
    if (!polygonCoords) {
        return;
    }

    // dummy, just to develop
    const coords = [
        {lat: 45.46464721523704, lng: 9.193580451683374},
        {lat: 45.46375929826689, lng: 9.19143468447146},
        {lat: 45.463202461845455, lng: 9.192657771782251},
        {lat: 45.46383454601011, lng: 9.193795028404566}
    ];

    const polygon = new googleMaps.Polygon({
        paths: coords,
        strokeWeight: polygonOptions.strokeWeight || 2,
        fillColor: polygonOptions.fillColor || '#FF0000',
        fillOpacity: polygonOptions.fillOpacity || 0.35,
        editable: polygonOptions.editable || true,
        draggable: polygonOptions.draggable || true
    });

    polygon.setMap(map);
    setSelection(polygon);
};

/**
 * TODO: not used: activate if polygonCreate(options) implementation is completed
 * Centers map
 *  1. If center is defined in mapOptions, that is used
 *  2. Else, address is geocoded and used
 */
const mapCenter = (options, map) => {
    if (options.mapOptions.center) {
        //console.log('options.center found -> setting center to ', options.mapOptions.center);
        map.setCenter(options.mapOptions.center);
        mapShow();
    } else {
        //console.log('options.center not found -> setting center to address ', options.address);
        mapGeocode(options.address, map);
    }
};

/**
 * Geocodes map to address latLng coordinates
 *  When geocoding completes:
 *  1. Shows map
 *  2. Centers map to address coordinates
 *  3. Drops a marker to address coordinates
 * @param {String} address 
 * @param {googleMaps.Map} map 
 */
const mapGeocode = (address, map) => {
    const geocoder = new googleMaps.Geocoder();
    // geocode async function
    geocoder.geocode({'address': address}, function(results, status) {
        mapShow();
        if (status === 'OK') {
            map.setCenter(results[0].geometry.location);
            const marker = new googleMaps.Marker({
                map: map,
                position: results[0].geometry.location,
                icon: getConfItem('gui.map.addressMarkerIcon')
            });
        } else {
            alert('Geocode was not successful for the following reason: ' + status);
        }
    });
};

/**
 * Creates drawingManager with a mix of default and arguments options
 * @param {Object} drawingManagerOptions
 *  https://google-developers.appspot.com/maps/documentation/javascript/reference/drawing#DrawingManagerOptions
 * @param {Object} polygonOptions 
 *  https://google-developers.appspot.com/maps/documentation/javascript/reference/polygon#PolygonOptions
 */
const drawingManagerCreate = (drawingManagerOptions = {}, polygonOptions = {}) => {

    // options
    const polygOptions = {...polygonOptionsDefault, ...polygonOptions};
    const options = {
        ...drawingManagerOptionsDefault,
        ...drawingManagerOptions,
        polygonOptions: polygOptions,
        map: map
    };

    // drawing manager
    drawingManager = new googleMaps.drawing.DrawingManager(options);

    // return
    return drawingManager;
};

const registerCallbacks = (options) => {
    onInitializedCallback = options.onInitializedCallback;
    updateShapeAreaCallback = options.updateShapeAreaCallback;
    onMapCenterChangedCallback = options.onMapCenterChangedCallback;
    onMapZoomChangedCallback = options.onMapZoomChangedCallback;
};

/**                                                                                         Events
 * ===============================================================================================
 */

const assignEvents = () => {
    map.addListener('center_changed', onMapCenterChanged);
    map.addListener('zoom_changed', onMapZoomChanged);
    googleMaps.event.addListener(drawingManager, 'overlaycomplete', onOverlayComplete);
    googleMaps.event.addListener(drawingManager,'polygoncomplete', onPolygonComplete);
    //googleMaps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
    googleMaps.event.addDomListener(domGetDeleteButton(), 'click', onClickDeleteButton);
};

const onMapCenterChanged = () => {
    //console.log('center_changed!!!');
    onMapCenterChangedCallback(map);
};

const onMapZoomChanged = () => {
    //console.log('zoom_changed!!!');
    onMapZoomChangedCallback(map);
};

/**
 * On overlay complete listener
 * Fired when an overlay is completed
 * In our case, fired when the polygon drawing is completed 
 *  1. Registers polygon as selected shape
 *  2. Updates the polygon area
 *  3. Exits drawing mode
 *  4. Shows delete button
 * */
const onOverlayComplete = (evt) => {

    const shape = evt.overlay;
    shape.type = evt.type;

    // a polygon type has been drawn
    if (evt.type === googleMaps.drawing.OverlayType.POLYGON) {
        // select newShape
        setSelection(shape);
        // update area
        updateShapeArea();
        // Switch back to non-drawing mode after drawing a shape.
        drawingManager.setDrawingMode(null);
        // show delete button
        deleteButtonShow();
    }

};

/**
 * On polygoncomplete listener
 * Sets up event listeners to update the polygon area when polygon path is changed
 * @param {google.maps.Polygon} polygon 
 */
const onPolygonComplete = (polygon) => {

    const path = polygon.getPath();

    googleMaps.event.addListener(polygon, 'click', function (e) {
        setSelection(polygon);
    });

    // Event listener fired when a path point is moved
    googleMaps.event.addListener(path, 'set_at', function(evt) {
        updateShapeArea();
    });

    // Event listener fired ehan a new path point is created
    googleMaps.event.addListener(path, 'insert_at', function(evt) {
        updateShapeArea();
    });
}

/**
 * 
 * @param {Event} evt 
 */
const onClickDeleteButton = (evt) => {
    deleteSelectedShape();
    clearSelection();
    deleteButtonHide();
    updateShapeArea();
    drawingManager.setDrawingMode(googleMaps.drawing.OverlayType.POLYGON);
};

/**                                                                                            Dom
 * ===============================================================================================
 */

const domGetMap = () => {
    return document.querySelector('.slc-roofArea-input-draw');
};

const mapShow = () => {
    if (!domGetMap()) {
        return;
    }
    domGetMap().style.opacity = 1;
};

const mapHide = () => {
    if (!domGetMap()) {
        return;
    }
    domGetMap().style.opacity = 0;
};

const domGetDeleteButton = () => {
    return document.querySelector('.slc-roofArea-input-draw-clear-btn');
};

const deleteButtonShow = () => {
    domGetDeleteButton().style.display = 'inline-block';
};

const deleteButtonHide = () => {
    domGetDeleteButton().style.display = 'none';
};

/**                                                                                    Map editing
 * ===============================================================================================
 */

/**
 * Resets the map drawings
 *  1. Deletes the selected shape
 *  2. Clears the selection on the selected shape
 *  3. Hides the delete button
 *  4. Sets drawing mode to googleMaps.drawing.OverlayType.POLYGON
 */
const resetDraw = () => {
    deleteSelectedShape();
    clearSelection();
    deleteButtonHide();
    drawingManager.setDrawingMode(googleMaps.drawing.OverlayType.POLYGON);
};

const clearSelection = () => {
    if (selectedShape) {
        if (selectedShape.type !== 'marker') {
            selectedShape.setEditable(false);
        }                    
        selectedShape = null;
    }
};

const setSelection = (shape) => {
    if (shape.type !== 'marker') {
        clearSelection();
        shape.setEditable(true);
    }                
    selectedShape = shape;
};

const deleteSelectedShape = () => {
    if (selectedShape) {
        selectedShape.setMap(null);
    }
};

/**                                                             Polygon geometry (area, center...)
 * ===============================================================================================
 */

/**
 * Returns the shape area
 * If shape is not defined, returns null
 * @param {GoogleMapShape} shape optional
 * @return {Float|null}
 */
const getShapeArea = (shape) => {
    shape = (shape) ? shape : selectedShape;
    if (!shape) {
        return null;
    }
    const area = googleMaps.geometry.spherical.computeArea(shape.getPath());
    return area;
};

/**
 * Updates shape area
 * Triggers update shape area callback
 */
const updateShapeArea = () => {
    const area = getShapeArea();
    updateShapeAreaCallback(area, map, selectedShape);
};

const getPolygonCenter = (polygon) => {
    return polygon.getCenter();
};

/**                                                                        Static image generation
 * ===============================================================================================
 */

 /**
  * Returns the maps's static image url inside a react component
  * Calculates the image size and scale properties, driven by sizeOrMapImageViewport
  * Wrapper to mapImageUrl(map, polygon, sizeAndScale)
  * @param {google.maps.Map} map 
  * @param {google.maps.Polygon} polygon 
  * @param {String} responsiveState 
  * @param {HTMLElement|String} sizeOrMapImageViewport
  *     The viewport (the parent html element) containing the google <img>
  *     Can be
  *         HTMLElement (the dom viewport)
  *             In this case, the viewport size is calculated
  *         String (the dom viewport css selector)
  *             In this case, the HTMLElement is fetched and the its size is calculted
  *         String (the viewport size straight away: what Google Static Image API actually needs to give us the image)
  *             Sample
  *                 '400x400'
  * @return {String}
  */
const mapImageUrlInReactComponent = (map, polygon, responsiveState, sizeOrMapImageViewport) => {

    if (!map) {
        return null;
    }

    const isSizeFormat = (sizeOrMapImageViewport) => {
        const pattern = /[\d]*x[\d]*/; // 400x300
        const test = pattern.test(sizeOrMapImageViewport);
        //console.log('    -> test = ', test);
        return (typeof(sizeOrMapImageViewport) === 'string' && pattern.test(sizeOrMapImageViewport));
    };

    const mapImageViewportGetDom = () => {
        return (typeof(mapImageViewport) === 'string') ? document.querySelector(sizeOrMapImageViewport) : sizeOrMapImageViewport ;
    };

    const mapImageViewportGetSize = () => {
        const element = mapImageViewportGetDom();
        return {
            width: element.clientWidth,
            height: element.clientHeight
        };
    };

    const mapImageViewportGetSizeToUrlValue = () => {
        const size = mapImageViewportGetSize();
        return size.width + 'x' + size.height;
    };

    return mapImageUrl(
        map,
        polygon,
        {
            size: (typeof(sizeOrMapImageViewport) === 'string') ? sizeOrMapImageViewport : mapImageViewportGetSizeToUrlValue(),
            // in tablet gui, image needs a 2 scale, othwerwise the image viewport would be wider than the image, resulting in a nasty graphic effect
            scale: (responsiveState === 'tablet') ? 2 : 1
        }
    );

};

/**
 * Returns the maps's static image url
 * Calculates all the Maps Static API needed options, driven by map, polygon and sizeAndScale arguments
 * Wrapper to getMapStaticImageUrl(mapOptions)
 * @param {google.maps.Map} map
 * @param {google.maps.Polygon} polygon
 * @param {Object} sizeAndScale
 *  .size {String}
 *      Sample
 *          '400x400'
 *  .scale {Number}
 *      Range
 *          [1|2]
 */
const mapImageUrl = (map, polygon, sizeAndScale = null) => {

    const mapCenter = map.getCenter();
    const center = (polygon) ? getPolygonCenter(polygon) : mapCenter ;
    const polygonPath = (polygon) ? polygon.getPath() : null;
    const pathValue = (polygon) ? pathToUrlValue(polygonPath, {
        color: colorHashTo0xFormat(polygon.fillColor),
        fillcolor: colorHashTo0xFormat(polygon.fillColor, 'AA'),
        weight: 1
    }) : null;
    const size = (sizeAndScale) ? sizeAndScale.size : getConfItem('gui.map.mapStaticImage.size');
    const scale = (sizeAndScale) ? sizeAndScale.scale : getConfItem('gui.map.mapStaticImage.scale');

    return getMapStaticImageUrl({
        center: center.toUrlValue(), // '41.902849377151014,12.497276193779612',
        zoom: map.getZoom(), // 16
        size: size, // '400x400'
        scale: scale, // 1
        maptype: getConfItem('gui.map.mapOptions.mapTypeId'), // 'satellite',
        path: pathValue, // 'color:yellow%7Cweight:1%7Cfillcolor:yellow%7C41.903015071785376,12.497107214611674%7C41.90247207455059,12.49696237532487%7C41.90265573589679,12.497590012234355%7C41.90322667975145,12.497391528767253%7C41.903015071785376,12.497107214611674&',
        key: googleMapsApiKey
    });

};

/**
 * Returns the Google Maps url for the image static generation of the map characterized by mapOptions
 * @param {Object} mapOptions
 *  .center {String}
 *      '41.902849377151014,12.497276193779612'
 *  .zoom {Number}
 *      16
 *  .size {String}
 *      '400x400'
 *  .maptype {String}
 *      'satellite'
 *  .path  {String}
 *      'color:yellow%7Cweight:1%7Cfillcolor:yellow%7C41.903015071785376,12.497107214611674%7C41.90247207455059,12.49696237532487%7C41.90265573589679,12.497590012234355%7C41.90322667975145,12.497391528767253%7C41.903015071785376,12.497107214611674&',
 *  .key {String}
 * @return {String}
 *  Sample
 *      http://maps.googleapis.com/maps/api/staticmap?
 *          center=41.902849377151014,12.497276193779612&
 *          zoom=16&
 *          size=400x400&
 *          maptype=satellite&
 *          path=color:yellow%7Cweight:1%7Cfillcolor:yellow%7C41.903015071785376,12.497107214611674%7C41.90247207455059,12.49696237532487%7C41.90265573589679,12.497590012234355%7C41.90322667975145,12.497391528767253%7C41.903015071785376,12.497107214611674&
 *          key=AIzaSyDaJLwsUmFr05RY1WbrzQ-V8suIicSFzIg
 */
const getMapStaticImageUrl = (mapOptions) => {
    let url = imageGeneratorUrl + '?';
    let params = '';
    for (let option in mapOptions) {
        if (mapOptions[option]) {
            params += option + '=' + mapOptions[option] + '&';
        }        
    }
    params = params.substr(0, params.length - 1);
    url += params;
    return url;
};

/**
 * Returns the 'path' param value of the Maps Static Api urls
 * @param {MVCArray<LatLng>} path
 * @param {Object} style
 * @return {String}
 *  Sample
 */
const pathToUrlValue = (path, style) => {
    const sep = '|';
    const styleValue = pathStyleToUrlValue(style);
    const pointValue = pathPointsToUrlValue(path);
    return (styleValue && pointValue) ? styleValue + sep + pointValue : 'INVALID PATH VALUE';
};

/**
 * Returns the path points url encoded string representation
 * Useful to feed the 'path' param value of the Maps Static Api urls
 * @param {MVCArray<LatLng>} path
 *  Fetched from shape.getPath()
 * @return {String}
 *  Sample
 *      '"45.46401834878704%2C9.190432795585366%7C45.46352883324106%2C9.190175303519936%7C45.463725704134646%2C9.191473492683144%7C45.46416201015138%2C9.191232093871804"'
 */
const pathPointsToUrlValue = (path) => {
    if (!path) {
        return null;
    }
    var value = '';
    for (var i = 0; i < path.getLength(); i++) {
        let xy = path.getAt(i);
        let sep = (value === '') ? '' : '|' ;
        value += sep + xy.lat() + ',' + xy.lng();
    }
    return encodeURIComponent(value);
};

/**
 * Returns style object as a string for url param usage
 * Useful to feed the 'path' param value of the Maps Static Api urls
 * @param {Object} style 
 * @return {String}
 *  Sample
 *      'color:yellow|weight:1|fillcolor:yellow' (this is a not url encoded format, just for easy reading)
 */
const pathStyleToUrlValue = (style) => {
    if (!style) {
        return null;
    }
    var value = '';
    for (let prop in style) {
        let sep = (value === '') ? '' : '|' ;
        value += sep + prop + ':' + style[prop];
    }
    return encodeURIComponent(value);
};

/**                                                                                          Utils
 * ===============================================================================================
 */

const colorHashTo0xFormat = (hashColorStr, opacity = 'FF') => {
    return hashColorStr.replace('#', '0x') + opacity;
};

/**                                                                                         Public
 * ===============================================================================================
 */

export {
    initialize,
    resetDraw,
    mapImageUrl,
    mapImageUrlInReactComponent,
    pathToUrlValue,
    pathPointsToUrlValue,
    colorHashTo0xFormat
};