/**
 * Loading Component
 * Renders a loading element.
 * The element can:
 * 1. Cover the entire viewport (default, position 'fixed')
 * 2. Cover only partial areas of the screen (position 'absolute')
 * @props
 *  .layout
 *      Range
 *          ['absolute']
 * @usage
 *  <Loading label="Loading" />
 *  <Loading label="Wait a moment, please" layout="absolute" />
 */

import React from 'react';

import './Loading.css';

const Loading = (props) => {

    /**
     * Returns the component base class name
     * @param {String} layout 
     * Determines if the css base class position is 'fixed' or 'absolute'
     * Defaults to 'fixed'
     * When 'fixed', the loader always covers the entire viewport
     * When 'absolute', the loader follows the css 'absolute' positioning rules
     * Range
     *  ['absolute']
     * @returns {String}
     *  Range
     *      ['slc-loading'|'slc-loading--absolute']
     */
    const getBaseClassName = (layout) => {
        let className = 'slc-loading';
        if (layout) {
            className += ' ' + 'slc-loading--' + layout;
        }
        return className;
    };

    return (
        <div className={getBaseClassName(props.layout)}>
            <div className="slc-loading-body">
                <div className="lds-dual-ring"></div>
                { props.label && <div className="slc-loading-label">{props.label}</div> }
            </div>
        </div>
    );
};

export default Loading;