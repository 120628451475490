import {
    STORE_RESET,
    APPLICATION_CONTEXT_UPDATE,
    APPLICATION_CONTEXT_UPDATE_CHANNEL,
    APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST,
    APPLICATION_CONTEXT_UPDATE_CAMPAIGN,
    GUI_UPDATE,
    GUI_ONPOPSTATE_LISTENER_ADD,
    GUI_WIZARD_NEXTAUTOSTEP_WATCHER_ADD,
    GUI_WIZARD_BUTTON_NAV_CLICKED,
    USER_UPDATE,
    CONFIGURATION_UPDATE,
    MAP_UPDATE,
    MAP_STATIC_IMAGE_URL_UPDATE,
    QUOTATIONS_UPDATE,
    QUOTATIONS_UPDATE_SNAPSHOT,
    QUOTATIONS_UPDATE_UUID,
    QUOTATIONS_UPDATE_TOTAL,
    QUOTATIONS_UPDATE_OPTION,
    QUOTATIONS_UPDATE_SELECTED_QUOTATION,
    QUOTATIONS_UPDATE_SELECTED_IMPIANTO,
    QUOTATIONS_FORM_UPDATE_FIELD,
    BATTERIES_UPDATE,
    BESTQUOTATIONS_UPDATE,
    ALTQUOTATIONS_UPDATE,
} from '../actions/action-types';
import initialState from './appInitialState';
const dotProp = require('dot-prop-immutable');

/**
 * This is the application reducer, providing the application state at each action dispatching
 * This is called each time a store.dispatch(action) call is made
 *
 * @param {Object} state
 * @param {Object} action
 *
 * @usages
 *  appStore.dispatch(updateGui({
 *      responsive: 'mobile'
 *  }));
 *
 *  appStore.dispatch(updateUser({
 *      address: 'Via del Cielo 2390'
 *  }));
 *
 *  appStore.dispatch(updateUser({
 *      address: 'Via del Cielo 2390'
 *  }));
 *
 *  appStore.dispatch(updateUser({
 *      address: 'Via Sticazzi 1111',
 *      email: 'f@f.it'
 *  }));
 *
 *  appStore.dispatch(updateConfiguration({
 *      roofArea: 776,
 *      roofInclination: 23
 *  }));
 */
function appReducer(state = initialState, action) {
    // //console.log('    state before = ', state);
    // //console.log('    action', action);

    let dataPath = '';

    switch (true) {
        /**
         * reset store must not applicationContext, because that is not concptually part of
         * ...the application state
         */
        case action.type === STORE_RESET:
            const oldState = state;
            state = initialState;
            state.applicationContext = oldState.applicationContext;
            state.gui.responsive = oldState.gui.responsive;
            break;
        case action.type === APPLICATION_CONTEXT_UPDATE:
            dataPath = 'applicationContext';
            state = dotProp.set(state, dataPath, action.payload);
            break;
        case action.type === APPLICATION_CONTEXT_UPDATE_CHANNEL:
            dataPath = 'applicationContext.ch';
            state = dotProp.set(state, dataPath, action.payload.ch);
            break;
        case action.type === APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST:
            dataPath = 'applicationContext.ss';
            state = dotProp.set(state, dataPath, action.payload.ss);
            break;
        case action.type === APPLICATION_CONTEXT_UPDATE_CAMPAIGN:
            dataPath = 'applicationContext.cmp';
            state = dotProp.set(state, dataPath, action.payload.cmp);
            break;
        case action.type === GUI_UPDATE:
            state = { ...state, gui: { ...state.gui, ...action.payload } };
            break;
        case action.type === GUI_ONPOPSTATE_LISTENER_ADD:
            dataPath = 'gui.onpopstateListeners';
            let listenerData = {};
            listenerData[action.payload.listenerId] = true;
            listenerData = {
                ...state.gui.onpopstateListeners,
                ...listenerData,
            };
            state = dotProp.set(state, dataPath, listenerData);
            break;
        case action.type === GUI_WIZARD_NEXTAUTOSTEP_WATCHER_ADD:
            dataPath = 'gui.wizardNextautoWatchers';
            let watcherData = {};
            watcherData[action.payload.listenerId] = true;
            watcherData = {
                ...state.gui.wizardNextautoWatchers,
                ...watcherData,
            };
            state = dotProp.set(state, dataPath, watcherData);
            break;
        case action.type === GUI_WIZARD_BUTTON_NAV_CLICKED:
            dataPath = 'gui.wizardButtonNavClicked';
            state = dotProp.set(state, dataPath, action.payload.clicked);
            break;
        case action.type === USER_UPDATE:
            state = { ...state, user: { ...state.user, ...action.payload } };
            break;
        case action.type === CONFIGURATION_UPDATE:
            state = {
                ...state,
                configuration: { ...state.configuration, ...action.payload },
            };
            break;
        case action.type === MAP_UPDATE:
            state = {
                ...state,
                map: {
                    ...state.map,
                    refs: {
                        ...state.map.refs,
                        ...action.payload.refs,
                    },
                    data: {
                        ...state.map.data,
                        ...action.payload.data,
                    },
                },
            };
            break;
        case action.type === MAP_STATIC_IMAGE_URL_UPDATE:
            state = {
                ...state,
                map: { ...state.map, staticImageUrl: action.payload.url },
            };
            break;
        case action.type === QUOTATIONS_UPDATE:
            state = { ...state, quotations: action.payload.quotations };
            break;

        case action.type === QUOTATIONS_UPDATE_SNAPSHOT:
            dataPath = 'quotations.snapshot';
            state = dotProp.set(state, dataPath, action.payload.snapshot);
            break;
        case action.type === QUOTATIONS_UPDATE_UUID:
            dataPath = 'quotations.uuid';
            state = dotProp.set(state, dataPath, action.payload.uuid);
            break;
        case action.type === QUOTATIONS_UPDATE_TOTAL:
            state = dotProp.set(
                state,
                `quotations.${action.payload.quotationType}.total`,
                action.payload.totalValue
            );
            break;
        case action.type === QUOTATIONS_UPDATE_OPTION:
            dataPath = `quotations.${action.payload.quotationType}.options.${action.payload.optionId}`;
            state = dotProp.set(state, dataPath, action.payload.optionValue);
            break;
        case action.type === QUOTATIONS_UPDATE_SELECTED_QUOTATION:
            dataPath = 'quotations.selectedQuotation';
            state = dotProp.set(state, dataPath, action.payload.quotationType);
            break;
        case action.type === QUOTATIONS_UPDATE_SELECTED_IMPIANTO:
            dataPath = 'quotations.selectedImpianto';
            state = dotProp.set(state, dataPath, action.payload.impianto);
            break;
        case action.type === QUOTATIONS_FORM_UPDATE_FIELD:
            state = {
                ...state,
                quotationsForm: { ...state.quotationsForm, ...action.payload },
            };
            break;
        case action.type === BATTERIES_UPDATE:
            dataPath = 'batteries';
            state = dotProp.set(state, dataPath, action.payload.batteries);
            break;
        case action.type === BESTQUOTATIONS_UPDATE:
            dataPath = 'bestquotations';
            state = dotProp.set(state, dataPath, action.payload.bestquotations);
            break;
        case action.type === ALTQUOTATIONS_UPDATE:
            dataPath = 'altquotations';
            state = dotProp.set(state, dataPath, action.payload.altquotations);
            break;
        default:
            state = state;
    }

    // //console.log('    state after = ', state);

    return state;
}

export default appReducer;
