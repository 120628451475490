/**
 * mobile-detector module
 * 
 * Stores responsive state into the appStore with javascript media query
 * Useful for media query dependant javascript logic throughout the application
 * Breakpoints:
 *  max-width:600px                         -> 'mobile'
 *  min-width:601px and max-width: 992px    -> 'tablet
 *  min-width:993px                         -> 'desktop'
 * 
 * @dependencies
 *  enquire.js (https://wicky.nillia.ms/enquire.js/)
 */

import enquire from 'enquire.js';
import appStore from '../redux/store/appStore';
import { updateGui } from '../redux/actions/actions';

const setup = () => {

    // mobile <=600px
    enquire.register("screen and (max-width:600px)", function() {
        appStore.dispatch(updateGui({
            responsive: 'mobile'
        }));
    });
    
    // tablet >=600px
    //@media screen and (max-width: 900px) and (min-width: 600px)
    enquire.register("screen and (min-width:601px) and (max-width: 992px)", function() {
        appStore.dispatch(updateGui({
            responsive: 'tablet'
        }));
    });
    
    // desktop >=600px
    enquire.register("screen and (min-width:993px)", function() {
        appStore.dispatch(updateGui({
            responsive: 'desktop'
        }));
    });
};

export {
    setup
};