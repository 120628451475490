import { useSelector } from 'react-redux';
import { pageComponentCssClass } from '../../helpers/components-helper';

const PageLayout = (props) => {
    const { pageId, header, body, footer } = props;

    const responsive = useSelector((state) => state.gui.responsive);

    return (
        <div className={pageComponentCssClass(pageId, responsive)}>
            <div className="row slc-page-header">
                <div className="col s12">{header}</div>
            </div>
            <div className="row slc-page-body">{body}</div>
            {footer ? <div className="slc-page-footer">{footer}</div> : null}
        </div>
    );
};

export default PageLayout;
