/**
 * Resources
 * 
 *  https://developers.google.com/maps/documentation/javascript/places-autocomplete
 *  https://developers.google.com/maps/documentation/javascript/places-autocomplete#add_autocomplete
 */

import appStore from '../redux/store/appStore';
import { updateUser } from '../redux/actions/actions';

import { getConfItem } from '../conf/conf';

import { some } from '../helpers/object-helper';
import { warn as guiFeedbackWarn } from '../libs/gui-feedback';

const googleMaps = window.google.maps;
const googleMapsApiKey = getConfItem('gui.map._k');

var autocomplete,
    autocompleteHTMLElement;

const DEFAULT_OPTIONS = {
    types: ['geocode'],
    componentRestrictions: {country: 'it'},
    fields: ['address_component', 'formatted_address'],
    boundsNearMe: true
}

function GoogleAutocompleteHelperException(message) {
    this.message = message;
    this.name = 'GoogleAutocompleteHelperException';
}

 /**
  * 
  * @param {Object} options
  *     .element {HTMLElement}
  *     .bounds {google.maps.LatLngBounds} optional
  *     .boundsNearMe {Boolean} optional
  * @usage
  *     
  *     Autocomplete in <input id="user-address">
  *     Bounds to user device (default)
  *         try {
  *             const autocomplete = autocompleteInitialize({
  *                 element: document.getElementById('user-address')
  *             });
  *         } catch(e) {
  *             console.error(e.name + ': ' + e.message);
  *         }
  * 
  *     Autocomplete in <input id="user-address">
  *     Bounds to google.maps.LatLngBounds object
  *         try {
  *             const autocomplete = autocompleteInitialize({
  *                 element: document.getElementById('user-address'),
  *                 bounds: new google.maps.LatLngBounds(
  *                     new google.maps.LatLng(-33.8902, 151.1759),
  *                     new google.maps.LatLng(-33.8474, 151.2631)
                    )
  *             });
  *         } catch(e) {
  *             console.error(e.name + ': ' + e.message);
  *         }
  * 
  *     Autocomplete in <input id="user-address">
  *     Does not bound to any location or area
  *         try {
  *             const autocomplete = autocompleteInitialize({
  *                 element: document.getElementById('user-address'),
  *                 boundsNearMe: false
  *             });
  *         } catch(e) {
  *             console.error(e.name + ': ' + e.message);
  *         }
  *                 
  */
const initialize = (options) => {

    //console.log('google-places-autocomplete-helper.initialize()');

    if (!options.element) {
        throw new GoogleAutocompleteHelperException(`Invalid autocomplete <input> HTMLElement.
        A valid HTMLElement is expected as options.element argument value for the google-places-autocomplete-helper.initialize(options) method.
        ${options.element} was received instead.
        Please define a valid HTMLElement as options.element value.`);
    }

    autocompleteHTMLElement = options.element;
    autocompleteHTMLElement.addEventListener('keypress', (evt) => {
        if (evt.which === 13) {
            evt.preventDefault();
            return false;
        }
    });

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.   
    autocomplete = new googleMaps.places.Autocomplete(
        autocompleteHTMLElement,
        {
            types: options.types || DEFAULT_OPTIONS.types
        }
    );

    // Sets the autocomplete result restrictions to only Italy (it) results
    autocomplete.setComponentRestrictions(options.componentRestrictions || DEFAULT_OPTIONS.componentRestrictions);

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(options.fields || DEFAULT_OPTIONS.fields);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', placeChangedListener);
    
    // sets bounds for results geo biasing
    setBounds(options);

    // return autocomplete instance to allow the calling context to customize it
    return autocomplete;
};

/**
 * Biases the autocomplete results to favor an approximate location or area.
 * The location or area is defined by a combination of two parameters:
 *  - bounds
 *  - boundsNearMe
 * bounds use a precisely defined google.maps.LatLngBounds object to define the favored location or area.
 * boundsNearMe sets the favored area near the user's device (using the geolocation api)
 * The logic combination is as follow:
 *  a) If bounds (options.bounds) is defined, those google.maps.LatLngBounds are used
 *  b) If boundsNearMe (options.boundsNearMe) is defined, that is used
 *  c) If boundsNearMe (options.boundsNearMe) is not defined, DEFAULT_OPTIONS.boundsNearMe is used
 * @param {Object} options
 *  .bounds {google.maps.LatLngBounds}
 *  .boundsNearMe {Boolean}
 */
const setBounds = (options) => {
    if (options.bounds) {
        autocomplete.setBounds(options.bounds);
    } else {
        const boundsNearMe = (options.boundsNearMe !== undefined) ? options.boundsNearMe : DEFAULT_OPTIONS.boundsNearMe;
        if (boundsNearMe) {
            autocompleteHTMLElement.addEventListener('focus', setBoundsNearMe);
        }
    }    
};

/**
 * Biases the autocomplete results to favor locations near the user device (defined by geolocation api)
 */
const setBoundsNearMe = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            var geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            var circle = new googleMaps.Circle({center: geolocation, radius: position.coords.accuracy});
            autocomplete.setBounds(circle.getBounds());
        });
    }
};

/**
 * Event listener for 'place_change' event
 */
const placeChangedListener = () => {

    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;
    const formattedAddress = place.formatted_address;

    if (addressComponents) {
        if (addressValidate(addressComponents, formattedAddress)) {
            addressToStore(addressComponents, formattedAddress);
        } else {
            guiFeedbackWarn('Indirizzo troppo generico: è necessario specificare almeno indirizzo e città');
        }
    } else {
        guiFeedbackWarn('E\' necessario scegliere un indirizzo dalle opzioni proposte');
    }
    
};

const addressValidate = (addressComponents, formattedAddress) => {
    addressComponents = addressComponents || autocomplete.getPlace().address_components;
    formattedAddress = formattedAddress || autocomplete.getPlace().formatted_address;
    const updateData = {
        address: formattedAddress || null,
        pv: addressComponentsGetProvince(addressComponents),
        city: addressComponentsGetCity(addressComponents),
        postalCode: addressComponentsGetPostalCode(addressComponents)
    };
    const someIsNull = some(function(prop, value){
        return value === null;
    }, updateData);

    return !someIsNull;
    
};

/**
 * 
 * @param {Object[]} addressComponents
 *  [n]
 *      .long_name {String}
 *      .short_name {String}
        .types {String[]}
 * @param {String} formattedAddress
 */
const addressToStore = (addressComponents, formattedAddress) => {
    //console.log('addressToStore()');
    addressComponents = addressComponents || autocomplete.getPlace().address_components;
    formattedAddress = formattedAddress || autocomplete.getPlace().formatted_address;
    const updateData = {
        address: formattedAddress || null,
        pv: addressComponentsGetProvince(addressComponents),
        city: addressComponentsGetCity(addressComponents),
        postalCode: addressComponentsGetPostalCode(addressComponents)
    };

    //console.log('updateData ', updateData);
    appStore.dispatch(updateUser(updateData));
};

const addressComponentsGetProvince = (addressComponents) => {
    return addressComponentsGetComponent(addressComponents, 'administrative_area_level_2', 'short_name');
};

const addressComponentsGetCity = (addressComponents) => {
    return addressComponentsGetComponent(addressComponents, 'administrative_area_level_3', 'short_name');
};

const addressComponentsGetPostalCode = (addressComponents) => {
    return addressComponentsGetComponent(addressComponents, 'postal_code', 'short_name');
};

const addressComponentsGetComponent = (addressComponents, type, property) => {
    /**
     * @param {String} accumulator
     *  Stores the value to be retrieved or null
     * @param {Object} current
     *  The current address component object
     *      .long_name {String}
     *      .short_name {String}
     *      .types {String[]}
     */
    try {
        const data = addressComponents.reduce(function(accumulator, current) {
            if (current.types.includes(type)) {
                return current[property];
            } else {
                return accumulator;
            }
        }, null);
        //console.log(type + ' = ' + data);
        return data;
    } catch(e) {
        console.error(e.name + ': ' + e.message);
        return null;
    }
    
};

export {
    initialize
};