import React from 'react';

import './QuotationImpossibleMessage.css';

const QuotationImpossibleMessage = ({message}) => {
    return (
        <div className="slc-quotation-impossible-message">
            <i className="material-icons slc-quotation-impossible-message-icon">report_problem</i>
            <div className="slc-quotation-impossible-message-text">{message}</div>
        </div>
    );
};

export default QuotationImpossibleMessage;