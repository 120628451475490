import { useSelector } from 'react-redux';

import './FormCouponTitle.css';

const FormCouponTitle = () => {
    const quotations = useSelector((state) => state.quotations);

    const solarSystem =
        quotations.snapshot.sistemaSolare[quotations.selectedQuotation];
    const total = `Totale ${
        quotations.snapshot.totale[quotations.selectedImpianto]
    } €`;

    return (
        <>
            <div className="slc-coupon-form__title__text">{solarSystem}</div>
            <div className="slc-coupon-form__title__price">{total}</div>
        </>
    );
};

export default FormCouponTitle;
