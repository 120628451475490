/**
 * Helper for browser navigation functionalities
 */

import { getConfUrlBasePathName } from '../conf/conf';

import appStore from '../redux/store/appStore';
import {
    updateApplicationContext,
    guiOnpopstateListenerAdd,
    guiWizardButtonNavClicked
} from '../redux/actions/actions';

import {
    stepUrlPatternGet as wizardStepUrlPatternGet
} from './wizard-helper';

import { getItem } from '../helpers/object-helper';

//const BASE_PATH = '/solarconfigurator/';
const BASE_PATH = `${ getConfUrlBasePathName() }/`;

/**
 * Pattern to get channel from location.href
 *  Sample
 *      From https://localhost:3000/ikea/wizard/step1?ss=pippo
 *      Extracts
 *          1: //localhost:3000/
 *          2: ikea
 */
//const PATTERN_CHANNEL = /(\/\/[\w\:\d]*\/)([\w\d\-]*)/;
const PATTERN_CHANNEL = /(http|https):\/\/[\w:\-\.]*\/([\w]*)/;
const PATTERN_SOLAR_SPECIALIST = /(ss\=)([\w\d\-]*)/;
const PATTERN_CAMPAIGN = /(cmp\=)([\w\d\-]*)/;

const isHomePage = () => {
    return document.location.pathname === BASE_PATH || `${document.location.pathname}/` === BASE_PATH;
};

/**
 * Initializes application context from url
 */
const urlContextInit = () => {
    urlContextToStore();
    window.history.replaceState({}, '', '');
};

/**
 * Rewrites url, adding the application get params
 * @param {String} url 
 * @return {String}
 */
const urlRewrite = (url) => {
    const rewritten = url + window.location.search;
    return rewritten;
};

/**
 * Rewrites url, adding the application get params as memorized in the Redux store
 * @param {String} url
 *  Sample
 *      'http://localhost:3000/solarconfigurator/wizard/step2'
 * @return {String}
 *      'http://localhost:3000/solarconfigurator/wizard/step2?ss=223136&utm_referrer=https://www.ikea.com/it/it/campaigns/pannelli-solari-e-sistemi-di-accumulo-ikea-pubb5b09592&itm_campaign=hp&itm_element=servizi&itm_content=pannellisolari'
 */
const urlRewriteWithSearchStringFromState = (url) => {
    return url + getItem('applicationContext.fullLocationSearchString', appStore.getState());
};

/**
 * Modify the current url in browser, adding the search string stored in the Redux application state
 * Skips th modification if a search string already exists in the current url
 */
const currentUrlAddSearchString = () => {
    if (document.location.search.length > 0) {
        return;
    }
    const currentUrl = document.location.href;
    const url = urlRewriteWithSearchStringFromState(currentUrl);
    window.history.replaceState({}, '', url);
};

/**
 * Returns channel (ch) from url
 * Sample
 *  From
 *      'https://localhost:3000/ikea/wizard/step1?ss=gianni&cmp=winter'
 *  returns
 *      'ikea'
 * @param {String} url 
 */
const urlGetChannel = (url = window.location.href) => {
    const matches = url.match(PATTERN_CHANNEL);
    if (! matches) {
        return null;
    }
    return (matches.length >= 2) ? matches[2] : null;
};

/**
 * Returns solar specialist (ss) from url
 * Sample
 *  From
 *      'https://localhost:3000/ikea/wizard/step1?ss=gianni&cmp=winter'
 *  returns
 *      'gianni'
 * @param {String} url 
 */
const urlGetSolarSpecialist = (url = window.location.href) => {
    const matches = url.match(PATTERN_SOLAR_SPECIALIST);
    if (! matches) {
        return null;
    }
    return (matches.length >= 2) ? matches[2] : null;
};

/**
 * Returns campaign (cmp) from url
 * Sample
 *  From
 *      'https://localhost:3000/ikea/wizard/step1?ss=gianni&cmp=winter'
 *  returns
 *      'winter'
 * @param {String} url 
 */
const urlGetCampaign = (url = window.location.href) => {
    const matches = url.match(PATTERN_CAMPAIGN);
    if (! matches) {
        return null;
    }
    return (matches.length >= 2) ? matches[2] : null;
};

/**
 * Returns the full url search string (the string containing the GET params)
 * The string format is sanitized also, because some links coming from IKEA are wrongly formatted
 * @return {String}
 *  Sample
 *      '?ss=223136&utm_referrer=https://www.ikea.com/it/it/campaigns/pannelli-solari-e-sistemi-di-accumulo-ikea-pubb5b09592&itm_campaign=hp&itm_element=servizi&itm_content=pannellisolari'
 */
const urlGetFullLocationSearchString = () => {
    const searchString = document.location.search;
    const sanitizeUrlFormatConfig = {
        pattern: /(?!^)\?/g,
        replace: '&'
    };
    const replaced = searchString.replace(sanitizeUrlFormatConfig.pattern, sanitizeUrlFormatConfig.replace);
    return replaced;
};

/**
 * Returns context from url
 * @param {String} url
 * @return {Object}
 *  .channel {String}
 *  .solarSpecialist {String}
 *  .campaign {String}
 */
const urlGetContext = (url = window.location.href) => {
    return {
        ch: urlGetChannel(url),
        ss: urlGetSolarSpecialist(url),
        cmp: urlGetCampaign(url),
        fullLocationSearchString: urlGetFullLocationSearchString()
    };
};

/**
 * Writes store's applicationContext fetching the data from url
 */
const urlContextToStore = (url = window.location.href) => {
    appStore.dispatch(updateApplicationContext(urlGetContext()));
};

/**
 * Starts a navigaton by wizard controls state (next/prev Wizard buttons has been clicked)
 */
const navigationByWizardControlsStart = () => {
    appStore.dispatch(guiWizardButtonNavClicked({
        clicked: true
    }));
};

/**
 * Returns if a navigation by wizard controls state is active
 * Navigation here concerns navigation across Steps in Wizard
 * Navigation by wizard controls state is opposed to navigation by browser controls state:
 *  1. by wizard
 *      next/prev Wizard buttons has been clicked
 *  2. by browser
 *      browser navigation controls (address bar, back/forward button) have been used to trigger Step loading
 * Navigation by wizard controls state is:
 *  1. Started at every prev/next Wizard navigation buttons click
 *  2. Ended at every Step.onComponentDidMount following the (1) click
 */
const navigationByWizardControlIsRunning = () => {
    return getItem('gui.wizardButtonNavClicked', appStore.getState());
};

/**
 * Ends a navigaton by wizard controls state (new Step after click did mount)
 */
const navigationByWizardControlsEnd = () => {
    appStore.dispatch(guiWizardButtonNavClicked({
        clicked: false
    }));
};

const stepComponentBrowserNavigationSetup = (StepComponent, stepNum) => {

    const appState = appStore.getState();
    const listenerId = 'step' + stepNum;

    // listener already exists -> return
    if (getItem('gui.onpopstateListeners', appState)[listenerId]) {
        return;
    }

    // register listener for stepNum Step (to avoid multiple registrations if the same listener)
    appStore.dispatch(guiOnpopstateListenerAdd({
        listenerId: listenerId
    }));

    /**
     * Register popstate listener for stepNum Step
     * The listener is triggerd when back/forward browser buttons are clicked
     * Wizard is jumped to the current Step Component, which is read from the browser location
     * */
     window.addEventListener('popstate', function(e) {
        const pattern = wizardStepUrlPatternGet(stepNum);
        const isCurrentStep = pattern.test(document.location);
        if (isCurrentStep) {
            StepComponent.props.jumpToStep(stepNum - 1);
        }
    });

};

export {
    isHomePage,
    urlRewrite,
    urlRewriteWithSearchStringFromState,
    currentUrlAddSearchString,
    urlContextInit,
    urlContextToStore,
    urlGetContext,
    urlGetChannel,
    urlGetSolarSpecialist,
    urlGetCampaign,
    navigationByWizardControlsStart,
    navigationByWizardControlIsRunning,
    navigationByWizardControlsEnd,
    stepComponentBrowserNavigationSetup
}