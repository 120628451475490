import React from 'react';

const StepCommonInfos = (props) => {
    return (
        <div className="slc-wizard-infos-step-common">
            Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos Wizard Common infos 
        </div>
    );
}

export default StepCommonInfos;