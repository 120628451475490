/**
 * Helper for React Component general functionalities
 */
/**
 * Returns the pageId page css class
 * @param {*} pageId 
 * @param {*} responsiveState 
 * @return {String}
 *  Sample
 *      'slc-page slc-page-home responsive-desktop'
 *      'slc-page slc-page-home responsive-tablet'
 *      'slc-page slc-page-wizard responsive-mobile'
 */
const pageComponentCssClass = (pageId, responsiveState) => {
    return 'slc-page slc-page-' + pageId + ' responsive-' + responsiveState;
};

export {
    pageComponentCssClass
}