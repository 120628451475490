import { getConfItem } from '../conf/conf';

import imageDesktop from '../images/home/home-1200.jpg';
import imageMobile from '../images/home/home-600.jpg';

import imageScontoEcobonusDesktop from '../images/home/sconto-ecobonus.png';
import imageScontoEcobonusTablet from '../images/home/sconto-ecobonus-300.png';
import imageScontoEcobonusMobile from '../images/home/sconto-ecobonus-200.png';

const contents = {
    image: {
        default: imageMobile,
        desktop: imageDesktop
    },
    imageScontoEcobonus: {
        mobile: imageScontoEcobonusMobile,
        tablet: imageScontoEcobonusTablet,
        desktop: imageScontoEcobonusDesktop
    },
    btnTxt: {
        default: 'Inizia',
        desktop: 'Inizia la configurazione'
    }
};

/**
 * Returns responsive specific (responsiveState) content for contentType contents
 * If a missing or wrong responsiveState is requested, the default content is returned
 * @param {String} contentType 
 *  ['image'|'btnTxt']
 * @param {String} responsiveState optional 
 *  ['mobile'|'tablet'|'desktop']
 */
const getContent = (contentType, responsiveState = 'default') => {
    if (! (contentType in contents)) {
        throw new Error(`Error: ${contentType} content does not exists.`);
    }
    if (! (responsiveState in contents[contentType])) {
        responsiveState = 'default';
    }
    return contents[contentType][responsiveState];
};

export default getContent;