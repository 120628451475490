const HtmlPlusMinusModel = {

    getEmptyItem: () => ({label: '', value: null}),

    getItemByValue: (items, value) => {
        if (!items || items.length === 0) {
            return HtmlPlusMinusModel.getEmptyItem();
        }
        return items.filter(item => item.value === value)[0];
    },
    
    getItemIndex: (items, item) => {
        if (!items || items.length === 0) {
            return HtmlPlusMinusModel.getEmptyItem();
        }
        return items.findIndex(iItem => iItem.value === item.value);
    },
    
    getItemIndexByValue: (items, value) => {
        if (!items || items.length === 0) {
            return HtmlPlusMinusModel.getEmptyItem();
        }
        return items.findIndex(item => item.value === value);
    },
    
    getNextItem: (items, currentSelectedItem) => {
    
        const currentSelectedItemIndex = HtmlPlusMinusModel.getItemIndex(items, currentSelectedItem);
        const nextItemIndex = currentSelectedItemIndex + 1;
        
        if (nextItemIndex <= items.length - 1) {
            return items[nextItemIndex];
        } else {
            return null;
        }
    
    },
    
    getPrevItem: (items, currentSelectedItem) => {
    
        const currentSelectedItemIndex = HtmlPlusMinusModel.getItemIndex(items, currentSelectedItem);
        const nextItemIndex = currentSelectedItemIndex - 1;
        
        if (nextItemIndex >= 0) {
            return items[nextItemIndex];
        } else {
            return null;
        }
    
    },

    readSelectedValueFromDom: htmlMinusPlusDomElement => {
        return htmlMinusPlusDomElement.getAttribute('data-selected-value');
    }

};

export default HtmlPlusMinusModel;