import HtmlLabel from "./HtmlLabel";
import HtmlCheckbox from "./HtmlCheckbox";
import HtmlSelect from "./HtmlSelect";
import HtmlButton from "./HtmlButton";
import HtmlPlusMinus from "./HtmlPlusMinus";

export default {
    HtmlLabel,
    HtmlCheckbox,
    HtmlSelect,
    HtmlButton,
    HtmlPlusMinus
};