/**
 * MenuSelector class
 * 
 * @prop {Array} itemsElements
 * @prop {HTMLElement} selectedItem
 * @prop {Function} guiHiliteFn
 * @prop {Function} guiDehiliteFn
 * @prop {Function} onItemEnterFn optional
 * @prop {Function} onItemLeaveFn optional
 * @prop {Function} onItemClickFn optional
 * @prop {Function} selectItemFn optional
 * 
 * @usage
 *  const menuSelector = new MenuSelector({
 *      itemsElements: document.querySelectorAll('.slc-roofInclination-option'),
 *      selectedElement: getSelectedOptionFromStore(),
 *      guiHiliteFn: optionGuiHilite,
 *      guiDehiliteFn: optionGuiDehilite,
 *      // update store on item click
 *      onItemClickFn: (itemElement) => {
 *          appStore.dispatch(updateConfiguration({
 *            roofInclination: getOptionElementValue(itemElement)
 *          }));
 *      }
 *  });
 */

class MenuSelector {

  constructor(options) {
    this.itemsElements = options.itemsElements || [];
    this.selectedItem = options.selectedElement || null;
    this.guiHiliteFn = options.guiHiliteFn || function(itemElement){};
    this.guiDehiliteFn = options.guiDehiliteFn || function(itemElement){};
    this.onItemEnterFn = options.onItemEnterFn || function(itemElement) {};
    this.onItemLeaveFn = options.onItemLeaveFn || function(itemElement) {};
    this.onItemClickFn = options.onItemClickFn || function(itemElement) {};
    this.selectItemFn = options.selectItemFn || function(itemElement) {};
    this.init();
  }

  /**
   * Inits the menu
   */
  init() {
    this.assignEvents();
    this.selectItemOnInit();
  }

  /**
   * Assigns events to menu items
   */
  assignEvents() {
    for (let itemElement of this.itemsElements) {
      itemElement.addEventListener('mouseenter', this.onItemEnter.bind(this));
      itemElement.addEventListener('mouseleave', this.onItemLeave.bind(this));
      itemElement.addEventListener('click', this.onItemClick.bind(this));
    }
  }

  /**
   * Selects this.selectedItem if it has any value
   * Called only on init()
   */
  selectItemOnInit() {
    if (this.selectedItem) {
      this.selectItem(this.selectedItem);
    }
  }

  /**
   * this.selectedItem setter
   * @param {HTMLElement} selectedElement 
   */
  setSelectedElement(selectedElement) {
    this.selectedItem = selectedElement;
  }

  /**
   * this.selectedItem getter 
   * @return {HTMLElement|null}
   */
  getSelectedElement() {
    return this.selectedItem;
  }

  /**
   * Selects itemElement
   * @param {HTMLElement} itemElement 
   */
  selectItem(itemElement) {
    if (this.selectedItem !== null) {
        this.guiDehiliteFn(this.selectedItem);
    }
    this.guiHiliteFn(itemElement);
    this.setSelectedElement(itemElement);
    // user defined callback
    this.selectItemFn(itemElement);
  }
  
  /**
   * 'mouseenter' event listener for items
   * @param {Object} evt 
   */
  onItemEnter(evt) {
    const itemElement = evt.currentTarget;
    if (itemElement !== this.selectedItem) {
        this.guiHiliteFn(itemElement);
    }
    // user defined callback
    this.onItemEnterFn(itemElement);
  }
  
  /**
   * 'mouseleave' event listener for items
   * @param {Object} evt 
   */
  onItemLeave(evt) {
    const itemElement = evt.currentTarget;
    if (itemElement !== this.selectedItem) {
        this.guiDehiliteFn(itemElement);
    }
    // user defined callback
    this.onItemLeaveFn(itemElement);
  }
  
  /**
   * 'click' event listener for items
   * @param {Object} evt 
   */
  onItemClick(evt) {
    const itemElement = evt.currentTarget;
    this.selectItem(itemElement);
    // user defined callback
    this.onItemClickFn(itemElement);
  }

}

export default MenuSelector;