import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ReduxProvider from './redux/store/Provider';
import QueryClientProvider from './queryClient/Provider';
import './index.css';
import * as serviceWorker from './serviceWorker';

createRoot(document.getElementById('root')).render(
    <ReduxProvider>
        <QueryClientProvider>
            <App />
        </QueryClientProvider>
    </ReduxProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
