import React from "react";
import inputMap from './HtmlInputMap';
import useWrapper from './useWrapper';

const defaultType = "HtmlLabel";

/**
 * Receives the dynamic component as a String.
 * The string is the key to reference the React Component in the inputMap.
 * @param {Object} props
 *  .type {String}
 */
const HtmlInput = props => {

    const { renderWithWrapper } = useWrapper();

    const HtmlInputComponent = inputMap[props.type || defaultType] || inputMap[defaultType]; // this does the magic: Capital letters name is comiled to createComponent method
    
    return (props.wrapper) ?
        renderWithWrapper(
            <HtmlInputComponent {...props} />,
            props.wrapper
        ) :
        <HtmlInputComponent {...props} /> ;

};

export default HtmlInput;
