/**
 * App Component
 * @Dependencies
 *  https://materializecss.com/
 *    http://fullstackhybrid.com/using-materializecss-with-reactjs/
 */

import React from 'react';

import { RouterProvider } from 'react-router-dom';

import { createRouter, getRoutes } from './helpers/router-helper';
import gaSetup from './helpers/google-analytics-helper';
import { setup as mobileDetectorSetup } from './helpers/mobile-detector';
import { urlContextInit } from './helpers/browser-navigation-helper';

import 'materialize-css/dist/css/materialize.min.css';
import './App.css';
import './fonts/novecentosanswide_book/stylesheet.css';
import './fonts/novecentosanswide_medium/stylesheet.css';
import './libs/ak-toastr/ak-toastr.min.css';

// Setup google analytics
gaSetup();

// Set up mobile detector
mobileDetectorSetup();

// Init url context
urlContextInit();

// Export App
const App = () => <RouterProvider router={createRouter(getRoutes())} />;

export default App;
