import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

const useNavPath = (path = null) => {
    // navPath state
    const [navPath, setNavPath] = useState(path);

    // navPath navigation
    const navigateToNavPath = () => {
        return <Navigate to={{ pathname: navPath }} />;
    };

    return [navPath, setNavPath, navigateToNavPath];
};

export default useNavPath;
