/**
 * PageQuotationsDesktop Component
 *
 * Desktop targeted PageQuotation
 */

import React, { Fragment } from 'react';

import QuotationsDesktop from './QuotationsDesktop';
import Hints from './Hints';
import FormModal from './Form/FormModal';

const PageQuotationsDesktop = (props) => {
    return (
        <Fragment>
            <div className="col s12 slc-page-quotations-main">
                <QuotationsDesktop
                    mapStaticImageUrl={props.mapStaticImageUrl}
                    loading={props.loading}
                    config={props.config}
                    valutazione={props.valutazione}
                    data={props.data}
                    navigateBack={props.navigateBack}
                    navigateToWizard={props.navigateToWizard}
                    onCheckboxChange={props.onCheckboxChange}
                    onSelectChange={props.onSelectChange}
                    onQuoteChooseButtonClick={props.onQuoteChooseButtonClick}
                    onLabelInfoClickAnywhere={props.onLabelInfoClickAnywhere}
                />
                <Hints />
            </div>
            {props.formModal.show && (
                <FormModal
                    onCloseButtonClick={props.formModal.onCloseClick}
                    onRestartClick={props.formModal.onRestartClick}
                />
            )}
        </Fragment>
    );
};

export default PageQuotationsDesktop;
