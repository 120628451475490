/**
 * state format
 *  .applicationContext
 *      .ch {String}
 *      .ss {String}
 *      .cmp {String}
 *      .fullLocationSearchString {String}
 *  .gui
 *      .responsive {String}
 *          ['desktop',|'mobile']
 *      .currentStep {Number}
 *      .currentStepTitle {String}
 *      .currentEditingData
 *      .onpopstateListeners {Object}
 *      .wizardNextautoWatchers {Object}
 *      .wizardButtonNavClicked {Boolean}
 *  .user
 *      .address {String}
        .pv {String}
        .city {String}
        .postalCode {String}
        .email {String}
 *  .configuration
 *      .roofArea {Number}
 *      .roofOrientation {Number}
 *      .roofInclination {Number}
 *      .roofTipology {String}
 *      .roofMaterial {String}
 *      .floorsNumber {Number}
 *      .electricityConsumption {String}
        .electricityConsumptionPersone
        .electricityConsumptionCost
 *      .houseUsage {String}
 *      .electricCar {Boolean}
 *  .map
 *      .refs
 *          .map {google.maps.Map}
 *          .polygon {google.maps.Polygon}
 *      .data {Object}
 *          ...
 *      .staticImageUrl {String}
 *  .quotations
 *      ...
 *  .bestquotations
 *      ...
 *  .quotationsForm
 *      ...
 *  .batteries {Object[]}
 *      [n]
 *          .code {String}
 *          .label {String}
 */

const initialState = {
    applicationContext: {
        ch: null,
        ss: null,
        cmp: null,
        fullLocationSearchString: null,
    },
    gui: {
        responsive: null,
        currentStep: null,
        currentStepTitle: null,
        currentEditingData: null,
        onpopstateListeners: {},
        wizardNextautoWatchers: {},
        wizardButtonNavClicked: false,
    },
    user: {
        address: null,
        pv: null,
        city: null,
        postalCode: null,
        email: null,
    },
    configuration: {
        roofArea: null,
        roofOrientation: null,
        roofInclination: null,
        roofTipology: '2falde',
        roofMaterial: 'Tegola',
        floorsNumber: null,
        electricityConsumption: null,
        electricityConsumptionPersone: null,
        electricityConsumptionCost: null,
        houseUsage: 'OccupataPost18',
        electricCar: 'No',
    },
    map: {
        refs: {
            map: null,
            polygon: null,
        },
        data: null,
        staticImageUrl: null,
    },
    /**
     * Holds the quotations state, as it is determined by the user choices
     *  .valutazione
     *      defined by the backend, immutable
     *  .basic
     *      defined by the backend and the frontend user choices
     *  .plus
     *      defined by the backend and the frontend user choices
     */
    quotations: {
        valutazione: {
            basic: null,
            plus: null,
        },
        uuid: null, // 'bff78db4-c7af-4dd1-bab8-7165d305fd02'
        selectedQuotation: null, // ['basic'|'plus']
        selectedImpianto: null, // ['basic'|'basic-accumulo'|'plus'|'plus-accumulo'|'plus-s'|'plus-s-accumulo']
        basic: {
            total: null,
            options: {
                //accumulatoreProposto: false, // (~ Aggiungi Sistema di Accumulo (hardcoded here: not anymore user settable as a checkbox))
                potenza: null, // (~ Potenza impianto fotovoltaico consigliato)
                numeroPannelli: null,
                capacitaSistemaAccumulo: null,
                ottimizzatori: false,
            },
        },
        plus: {
            total: null,
            options: {
                //accumulatoreProposto: false, // (~ Aggiungi Sistema di Accumulo (hardcoded here: not anymore user settable as a checkbox))
                potenza: null, // (~ Potenza impianto fotovoltaico consigliato)
                numeroPannelli: null,
                capacitaSistemaAccumulo: null,
                ottimizzatori: false,
            },
        },
        /**
         * This snapshots the full current quotations values
         * On /quote response, this is equal to bestquotations
         * When the user chooses different values from interactive widgets, these changes are refelected here
         * This is matched against bestquotations to see if the user has choosen an alternative quotation
         * The data structure is as defined in mock-data/quote-service-response.json -> quotations
         * These are only sample values to show the data structure.
         */
        snapshot: null,
    },
    /**
     * The best quotations, as advised from the product owner
     * These are received by the /quote service
     * On /quote response, this is equal to quotations.snapshot
     * After /quore response, this remains unchanged
     * This is matched against quotations.snapshot to see if the user has choosen an alternative quotation
     * The data structure is as defined in mock-data/quote-service-response.json -> quotations
     */
    bestquotations: null,
    /**
     * The alternative quotations, i.e the quotations when the user chooses numeroPannelli and/or capacitaSistemaAccumulo other than the bestquotations values
     * These are received by the /quote service.
     * The data structure is as defined in mock-data/quote-service-response.json -> altquotations
     */
    altquotations: null,
    /**
     * The available batteries that can be chosen from the user in Page Quotations.These are received by the /quote service.
     * The data structure is as defined in mock-data/quote-service-response.json -> batteries
     */
    batteries: null,
    quotationsForm: {
        valid: false,
        coupon: null,
        nome: null,
        cognome: null,
        indirizzo: null,
        email: null,
        telefono: null,
        consensoAuthorizeA: null,
        consensoAuthorizeB: null,
        consensoAuthorizeC: null,
    },
};

export default initialState;
