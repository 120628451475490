import React, { Component, Fragment } from 'react';

import './PageHomeContent.css';

class PageHomeContent extends Component {

    constructor(props) {
        super(props);
        this.autocompleteInit = this.props.autocompleteInit;
    }

    componentDidMount() {
        this.autocompleteInit();
    }

    getRow2() {
        if (this.props.responsive === 'mobile') {
            return (
                <Fragment>
                {this.getFormComponent()}
                {this.getLinkToBattery()}
                </Fragment>
            );
        } else {
            return this.getStepsComponent();
        }
    }

    getRow3() {
        if (this.props.responsive === 'mobile') {
            return this.getStepsComponent();
        } else {
            return (
                <Fragment>
                {this.getFormComponent()}
                {this.getLinkToBattery()}
                </Fragment>
            );
        }
    }

    getStepsComponent() {
        return <Steps />;
    }

    getFormComponent() {
        return <Form
            onSubmit={this.props.onSubmit}
            btnTxt={this.props.btnTxt} />;
    }

    getLinkToBattery() {
        return <div className="slc-container slc-link-battery"><a className="slc-text-link" href="https://ikea.wolmann.com/storageconfigurator"><span>Mi interessa installare solo il Sistema di Accumulo</span></a></div>;
    }

    render() {
        return (
            <Fragment>
                <div className="slc-page-row">
                    <div className="slc-container slc-infos">
                        <div className="slc-infos_title">{this.props.title}</div>
                        <div className="slc-infos_infos">{this.props.infos}</div>
                    </div>
                </div>
                <div className="slc-page-row">
                    {this.getRow2()}
                </div>
                <div className="slc-page-row">
                    {this.getRow3()}
                </div>
                <div className="slc-page-row">
                    <div className="slc-container slc-image-container">
                        <img className="slc-image" src={this.props.imagePath} alt="" />
                    </div>
                </div>
            </Fragment>
        );
    }
    
};

const Steps = () => {
    return (
        <div className="slc-container slc-steps slc-font-title">
            <div className="slc-steps_step">
                <div className="slc-steps_step_number">1</div>
                <div className="slc-steps_step_text">Indica dove<br /> è il tuo<br />tetto</div>
            </div>
            <div className="slc-steps_step">
                <div className="slc-steps_step_number">2</div>
                <div className="slc-steps_step_text">Inserisci<br />quanto<br />consumi</div>
            </div>
            <div className="slc-steps_step">
                <div className="slc-steps_step_number">3</div>
                <div className="slc-steps_step_text">Ti diamo<br />il prezzo<br />"chiavi in mano"</div>
            </div>
        </div>
    );
};

const Form = (props) => {
    return (
        <div className="slc-container slc-form">
            <input type="text" className="slc-form-input slc-form-input-text user-address" id="user-address" placeholder="Inserisci l'indirizzo del tuo tetto"></input>
            <input type="text" className="slc-form-input slc-form-input-text user-email hide" id="user-email" placeholder="Inserisci la tua e-mail (facoltativo)"></input>
            <button className="slc-page-body__content__button btn-small" id="slc-btn-start" onClick={props.onSubmit}>{props.btnTxt}</button>
        </div>
    );
};

export default PageHomeContent;