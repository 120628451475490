const aktoastr = require('./ak-toastr/ak-toastr');

aktoastr.config({
    toastrOptions: {
        positionClass: 'toast-center',
        noIcon: true,
        tapToDismiss: false, // disables hide on click
        preventDuplicates: true,
        timeOut: 3500,
        extendedTimeOut: 0,
        //noAutoHide: true
    },
});

const warn = (message, options = undefined) => {
    aktoastr.render({
        type: 'warning',
        message: message,
        toastrOptions: options,
    });
};

const error = (message, options = undefined) => {
    aktoastr.render({
        type: 'error',
        message: message,
        toastrOptions: options,
    });
};

const info = (message, options = undefined) => {
    aktoastr.render({
        type: 'info',
        message: message,
        toastrOptions: options,
    });
};

const ok = (message, options = undefined) => {
    aktoastr.render({
        type: 'success',
        message: message,
        toastrOptions: options,
    });
};

export { warn, error, info, ok };
