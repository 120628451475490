import React from "react";

const defaults = {
  className: 'html-label',
  value: 'my-label'
};

const HtmlLabel = ({
    className,
    value
}) => {

  className = className || defaults.className;
  value = value || defaults.value;

  return (
      <div className={ className }>{ value }</div>
  );
  
};

export default HtmlLabel;
