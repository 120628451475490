import React from 'react';
import './Step1Infos.css';
import imgEsempio from '../../../images/configuratore-esempio.gif'; 

const Step1Infos = (props) => {
    return (
        <div className="slc-wizard-infos-step slc-wizard-infos-step-1">
        Seleziona la superficie totale di TUTTO il tetto. Posiziona il puntatore sul primo angolo. Clicca e sposta il mouse sul secondo angolo dell’area che vuoi delimitare. Clicca e ripeti l’operazione per tutti gli angoli del tetto.<br />
        Puoi ingrandire e ridurre la mappa: fai clic sui pulsanti zoom ( + ) e ( - ) nell'angolo inferiore destro.<br />
        Per  disegnare nuovamente il contorno del tuo tetto clicca il tasto  “Cancella” in alto a sinistra.<br />
        <img src={imgEsempio} alt="Esempio" className="slc-wizard-infos-step-1__image" />
        </div>
    );
}

export default Step1Infos;