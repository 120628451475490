import { updateQuotationsFormField } from '../../../../redux/actions/actions';

const COUPON_REGEX = /^[\w]{4,}$/;
const COUPON_VALID = 'valid';
const COUPON_INVALID = 'invalid';
const COUPON_MESSAGE_VALID = "E' stato applicato lo sconto coupon sul prezzo!";
const COUPON_MESSAGE_ERROR =
    'Non siamo riusciti a validare il tuo coupon.<br />Per favore riprova.';

const updateStoreCoupon = (couponData, dispatch) => {
    dispatch(
        updateQuotationsFormField({
            coupon: couponData.type === COUPON_VALID ? couponData.coupon : null,
        })
    );
};

const couponValidationSucceeded = (couponValidateMutation) =>
    couponValidateMutation.isSuccess &&
    couponValidateMutation.data &&
    isValidCoupon(couponValidateMutation.data.data);

const isValidCoupon = (coupon) => coupon.type === COUPON_VALID;
const isInvalidCoupon = (coupon) => coupon.type === COUPON_INVALID;

export {
    couponValidationSucceeded,
    isValidCoupon,
    isInvalidCoupon,
    updateStoreCoupon,
    COUPON_REGEX,
    COUPON_MESSAGE_VALID,
    COUPON_MESSAGE_ERROR,
};
