/**
 * Form Component
 *
 * @state
 *  .loading {Boolean}
 *  .quotationResponse {Object}
 *      .result {String}
 *          ['success'|'fail'|null]
 *      .message {String}
 *  .consensi
 *      .panelOpen {Boolean}
 *      .values
 *          .consensoAuthorizeA {Boolean}
 *          .consensoAuthorizeB {Boolean}
 *          .consensoAuthorizeC {Boolean}
 */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import appStore from '../../../../redux/store/appStore';
import { updateQuotationsFormField } from '../../../../redux/actions/actions';

import {
    config as httpClientConfig,
    quotationGenerate as httpClientQuotationGenerate,
} from '../../../../libs/http-client';
import { quotationGenerateGetRequestPayload } from '../../../../libs/http-client-dataManager';

import { getConfItem, getLabel } from '../../../../conf/conf';

import Loading from '../../../Loading';
import FormConsensoItem from './FormConsensoItem';
import FormResponse from './FormResponse';
import formHelper, {
    config as formConfig,
} from '../../../../helpers/quotations-form-helper';

import './Form.css';

const mapStateToProps = (state) => {
    return {
        user: state.user,
        quotationsForm: state.quotationsForm,
    };
};

const consensiData = [
    {
        id: 'consensoAuthorizeA',
        content:
            'Consenso per contatto per <a href="https://www.wolmann.com/pp_promozioni" target="_blank" onClick="event.stopPropagation();">promozioni</a>',
        domElement: () => document.querySelector('.consensoAuthorizeA'),
    },
    {
        id: 'consensoAuthorizeB',
        content:
            'Consenso per contatto per <a href="https://www.wolmann.com/pp_promozione_terzi" target="_blank" onClick="event.stopPropagation();">promozioni di terzi</a>',
        domElement: () => document.querySelector('.consensoAuthorizeB'),
    },
    {
        id: 'consensoAuthorizeC',
        content:
            'Consenso per <a href="https://www.wolmann.com/pp_profilazione_dati" target="_blank" onClick="event.stopPropagation();">profilazione dati</a>',
        domElement: () => document.querySelector('.consensoAuthorizeC'),
    },
];
const QUOTATION_RESPONSE_SUCCESS_MESSAGE = `Grazie per aver utilizzato il nostro configuratore.<br />Ti abbiamo inviato un'email con il tuo preventivo.`;
const QUOTATION_RESPONSE_FAIL_MESSAGE = `Grazie per aver utilizzato il nostro configuratore.<br />Purtroppo non è stato possibile inviarti un'email con il preventivo.`;
const QUOTATION_RESPONSE_HTTP_ERROR_MESSAGE = `Non è stato possibile soddisfare la richiesta.`;

class Form extends Component {
    constructor(props) {
        super(props);

        // init state
        this.state = {
            quotationResponse: {
                result: null,
                message: null,
            },
            consensi: {
                panelOpen: false,
                values: {
                    consensoAuthorizeA: true,
                    consensoAuthorizeB: true,
                    consensoAuthorizeC: true,
                },
            },
        };

        // bind event listeners
        this.quotationResponseReset = this.quotationResponseReset.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changeFormFieldListener = this.changeFormFieldListener.bind(this);
        this.changeFormCheckboxListener =
            this.changeFormCheckboxListener.bind(this);
        //this.changePrivacyListener = this.changePrivacyListener.bind(this);
        //this.changeConsensoListener = this.changeConsensoListener.bind(this);
        //this.closeConsensiListener = this.closeConsensiListener.bind(this);
        //this.updateConsensoValue = this.updateConsensoValue.bind(this);
        //this.toggleConsensiPanel = this.toggleConsensiPanel.bind(this);
        //this.formConsensiValidate = this.formConsensiValidate.bind(this);

        /**
         * Redux Store listener
         * Updates submit button state, if fomr exists in dom
         *  */
        appStore.subscribe(() => {
            if (this.formExistsInDom()) {
                this.submitBtnManageState();
            }
        });
    }

    componentDidMount() {
        httpClientConfig({
            baseUrl: getConfItem('api.' + process.env.NODE_ENV + '.baseUrl'),
        });
        if (!this.props.quotationsForm.indirizzo) {
            appStore.dispatch(
                updateQuotationsFormField({
                    indirizzo: this.props.user.address,
                })
            );
        }
        formHelper.formStart(formConfig);
    }

    /**
     * Data
     */

    /**
     * Triggers quotation generation by the SolarConfig API
     */
    quotationGenerate() {
        this.setState((state, props) => ({
            loading: true,
        }));
        httpClientQuotationGenerate(quotationGenerateGetRequestPayload())
            /**
             * Success response
             * @param {Object} response
             *  .config (axios config)
             *  .data (actual data)
             *  .headers (http headers)
             *  .request (xmlhttp)
             */
            .then(
                function (response) {
                    this.onQuotationGenerateResponse(response.data);
                }.bind(this)
            )
            .catch(
                function (error) {
                    this.quotationResponseHttpError(error);
                    /*
                * Catches responses with error status codes (300, 400, 500)
                * Error cases:
                *  status === 500 && data.result === 'ko'
                * All other cases are evaluated as success cases
                * @param {Object} error
                if (error.response) {
                    //console.log('error data = ', error.response.data);
                    //console.log('error status = ', error.response.status);
                    //console.log('error headers = ', error.response.headers);
                    switch(true) {
                        case (error.response.status === 500 && error.response.data.result === 'ko'):
                            //console.log('status 500 && result ko');
                            this.quotationResponseFail(error.response);
                            break;
                        default:
                            //console.log('status NOT 500 && result ko');
                            this.quotationResponseOk(error.response);
                    }
                } else {
                    console.error('No response received by the server');
                }
                */
                }.bind(this)
            );
    }

    /**
     * Handles quotationGenerate() response with status code 200
     * The response.result data is evaluated immediately in the promise catch clause, because it must be combined
     * ...with http status code value
     */
    onQuotationGenerateResponse(response) {
        if (response.result && response.result === 'ok') {
            this.quotationResponseOk(response);
        } else {
            this.quotationResponseFail(response);
        }
    }

    quotationResponseOk(response) {
        this.setState((state, props) => ({
            loading: false,
            quotationResponse: {
                result: 'success',
                message: QUOTATION_RESPONSE_SUCCESS_MESSAGE,
            },
        }));
    }

    quotationResponseFail(response) {
        this.setState((state, props) => ({
            loading: false,
            quotationResponse: {
                result: 'fail',
                message: QUOTATION_RESPONSE_FAIL_MESSAGE,
            },
        }));
    }

    quotationResponseHttpError(error) {
        this.setState((state, props) => ({
            loading: false,
            quotationResponse: {
                result: 'fail',
                message: QUOTATION_RESPONSE_HTTP_ERROR_MESSAGE,
            },
        }));
    }

    quotationResponseReset() {
        this.setState((state, props) => ({
            quotationResponse: {
                result: null,
                message: null,
            },
        }));
    }

    /**
     * Form.js fields
     */

    /**
     * Event listener for click event on submit btn
     * a) If form is validated, the /genquote web service is requested
     * b) If form is not validated, gui invalid view is rendered
     * @param {Event} evt
     */
    onSubmit(evt) {
        const validationResult = formHelper.formValidate(formConfig);
        if (validationResult.valid) {
            this.quotationGenerate();
        } else {
            formHelper.domGuiFieldsInvalid(
                validationResult.invalidFields,
                formConfig
            );
        }
    }

    /**
     * Returns if Form already exists in dom
     * @return {Boolean}
     */
    formExistsInDom() {
        return document.querySelector('.slc-quotations-form') !== null;
    }

    /**
     * Event listener for any input element change event in Form.js
     * @param {*} evt
     */
    changeFormFieldListener(evt) {
        const $field = evt.target;
        const value = $field.value;
        const fieldId = formHelper.fieldResolveId($field);
        const fieldConfig = formHelper.fieldGetConfig(fieldId, formConfig);

        const validated = formHelper.fieldValidate($field, fieldConfig);

        if (validated) {
            const payload = {};
            payload[fieldId] = value;
            appStore.dispatch(updateQuotationsFormField(payload));
            formHelper.domGuiFieldValid($field);
        } else {
            const payload = {};
            payload[fieldId] = null;
            appStore.dispatch(updateQuotationsFormField(payload));
            formHelper.domGuiFieldInvalid($field);
        }

        this.submitBtnManageState();
    }

    changeFormCheckboxListener(evt) {
        let $field =
            evt.target.nodeName === 'INPUT' ? evt.target : evt.currentTarget;

        // if clicked occurred on the checkbox's container div -> affect the checked state and reassign $field to $checkbox
        if (evt.target.nodeName === 'DIV') {
            let $checkbox = $field.firstChild.firstChild;
            $checkbox.checked = !$checkbox.checked;
            $field = $checkbox;
        }

        const value = formHelper.domCheckboxGetValue($field);
        const fieldId = formHelper.fieldResolveId($field);
        const fieldConfig = formHelper.fieldGetConfig(fieldId, formConfig);

        const validated = formHelper.fieldValidate($field, fieldConfig);

        if (validated) {
            const payload = {};
            payload[fieldId] = value;
            appStore.dispatch(updateQuotationsFormField(payload));
            formHelper.domGuiFieldValid($field);
        } else {
            const payload = {};
            payload[fieldId] = null;
            appStore.dispatch(updateQuotationsFormField(payload));
            formHelper.domGuiFieldInvalid($field);
        }

        this.submitBtnManageState();
    }

    /**
     * Manages the submit btn state
     * Simple wrapper to formHelper.submitBtnManageState(formConfig) function
     */
    submitBtnManageState() {
        formHelper.submitBtnManageState(formConfig);
    }

    render() {
        const loading = this.state.loading ? (
            <Loading label={getLabel('gui.loading')} layout="absolute" />
        ) : null;

        return (
            <div className="slc-quotations-form">
                {loading}
                <FormResponse
                    quotationResponse={this.state.quotationResponse}
                    quotationResponseReset={this.quotationResponseReset}
                />
                <div className="slc-quotations-form__title slc-font-title">
                    Inserisci i tuoi dati
                </div>
                <div className="slc-quotations-form__form">
                    <div className="slc-form-input-text-widget" id="nome">
                        <div className="slc-form-input-text-widget__label">
                            Nome
                        </div>
                        <div className="slc-form-input-text-widget__input-container">
                            <input
                                type="text"
                                className="slc-form-input-text-widget__input slc-form-input-text-widget__input-nome"
                                onChange={this.changeFormFieldListener}
                            />
                        </div>
                    </div>

                    <div className="slc-form-input-text-widget" id="cognome">
                        <div className="slc-form-input-text-widget__label">
                            Cognome
                        </div>
                        <div className="slc-form-input-text-widget__input-container">
                            <input
                                type="text"
                                className="slc-form-input-text-widget__input slc-form-input-text-widget__input-cognome"
                                onChange={this.changeFormFieldListener}
                            />
                        </div>
                    </div>

                    <div className="slc-form-input-text-widget" id="indirizzo">
                        <div className="slc-form-input-text-widget__label">
                            Indirizzo
                        </div>
                        <div className="slc-form-input-text-widget__input-container">
                            <input
                                type="text"
                                className="slc-form-input-text-widget__input slc-form-input-text-widget__input-indirizzo"
                                value={this.props.quotationsForm.indirizzo}
                                onChange={this.changeFormFieldListener}
                            />
                        </div>
                    </div>

                    <div className="slc-form-input-text-widget" id="email">
                        <div className="slc-form-input-text-widget__label">
                            E-mail
                        </div>
                        <div className="slc-form-input-text-widget__input-container">
                            <input
                                type="text"
                                className="slc-form-input-text-widget__input slc-form-input-text-widget__input-email"
                                onChange={this.changeFormFieldListener}
                            />
                        </div>
                    </div>

                    <div className="slc-form-input-text-widget" id="telefono">
                        <div className="slc-form-input-text-widget__label">
                            Telefono
                        </div>
                        <div className="slc-form-input-text-widget__input-container">
                            <input
                                type="text"
                                className="slc-form-input-text-widget__input slc-form-input-text-widget__input-telefono"
                                onChange={this.changeFormFieldListener}
                            />
                        </div>
                    </div>

                    <div className="consensoReadPrivacy">
                        I tuoi dati saranno trattati ai sensi di quanto previsto
                        dall'
                        <a
                            href="https://www.wolmann.com/privacy-policy"
                            target="_blank"
                            rel="noreferrer">
                            Informativa sulla Privacy
                        </a>
                    </div>

                    {consensiData.map((item) => (
                        <FormConsensoItem
                            key={item.id}
                            id={item.id}
                            content={item.content}
                            changeFormCheckboxListener={
                                this.changeFormCheckboxListener
                            }
                        />
                    ))}

                    <div className="slc-quotation-form_buttons">
                        <button
                            className="btn btn-next btn-primary btn-lg pull-right"
                            id="submit"
                            onClick={this.onSubmit}>
                            Ricevi l'offerta e prenota il
                            <br /> sopralluogo a casa tua!
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Form);
