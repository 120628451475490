import ReactGA from 'react-ga';

//const GA_ID = 'UA-146913752-1'; // andrea.padovani@gmail.com
//const GA_ID = 'UA-150214225-1'; // amteksrl@gmail.com
const GA_ID = 'UA-150368485-2'; // wolmanndev@gmail.com          

const _getPageUrl = function() {
    return window.location.pathname + window.location.search;
};

const gaSetup = function() {
    ReactGA.initialize(GA_ID);
}

/**
 * @param {String} page
 *  = window.location.pathname
 */
const gaTrackPage = (pageUrl = _getPageUrl(), options = {}) => {
    ReactGA.set({
        pageUrl,
      ...options
    });
    ReactGA.pageview(pageUrl);
};

export default gaSetup;
export {
    gaTrackPage
}