import React from 'react';

const Step3Infos = (props) => {
    return (
        <div className="slc-wizard-infos-step slc-wizard-infos-step-3">
        Indica l'inclinazione del tuo tetto, se è piatto o ha una pendenza.<br />La maggior parte dei tetti a falda in Italia presenta una inclinazione di 20° che comunemente considerata standard.<br />Nelle zone di alta montagna, a causa dello smaltimento della neve, molti edifici hanno una pendenza molto elevata.<br />Nell'Italia meridionale sono spesso presenti tetti piani.
        </div>
    );
}

export default Step3Infos;