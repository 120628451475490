import React from 'react';

import { connect } from "react-redux";

import StepCommonInfos from './StepCommonInfos';
import Step1Infos from './Step1Infos';
import Step2Infos from './Step2Infos';
import Step3Infos from './Step3Infos';
import Step4Infos from './Step4Infos';
import Step5Infos from './Step5Infos';

import './WizardInfos.css';

const mapInfos = {
    step1: <Step1Infos />,
    step2: <Step2Infos />,
    step3: <Step3Infos />,
    step4: <Step4Infos />,
    step5: <Step5Infos />
};

const mapStateToProps = state => {
    return {
        gui: state.gui
    };
};

/**
 * Returns the infos react component, driven by step number.
 *  a. If a specific step infos component exists for step, it is returned
 *  b. If no specific step infos component exists for step, StepCommonInfos component is returned
 * Specific step infos components must be defined in the mapInfos Object at line 8
 * 
 * @param {Number} step
 * @return {React Component}
 */
const getInfos = (step) => {
    const currentStepInfos = mapInfos['step' + step];
    return (currentStepInfos) ? currentStepInfos : <StepCommonInfos />;
};

const WizardInfos = (props) => {

    const operationsAllow = () => {
        return (props.gui.responsive === 'mobile' || props.gui.responsive === 'tablet' || props.gui.responsive === null);
    }

    const onCloseBtnClick = (evt) => {
        if (operationsAllow()) {
            props.closeInfosFn();
        }
    };

    const onOpenBtnClick = (evt) => {
        if (operationsAllow()) {
            props.openInfosFn();
        }
    };

    return (
        <div className='slc-wizard-infos'>
            <div className="slc-wizard-infos-btn slc-wizard-infos-btn-open" onClick={onOpenBtnClick}>?</div>
            <div className="slc-wizard-infos-btn slc-wizard-infos-btn-close" onClick={onCloseBtnClick}>x</div>
            {getInfos(props.gui.currentStep)}
        </div>
    );
}

export default connect(mapStateToProps)(WizardInfos);