/**
 * Wizard Component
 *
 *  Implements the configurator wizard.
 *  Goes from Step1 to Step9 Components.
 *
 *  Steps validation
 *
 *      Each step is validated: validation allows or denies the possibility of going to the next step.
 *      Validation is accomplished by the Step<n>.isValidated() method.
 *      Validation (the Step<n>.isValidated() method) is triggered by two different events:
 *          - btn next click
 *          - appStore event dispatch
 *
 *  Components Dependencies
 *
 *      Stepzilla
 *      Step1...Step9
 */

import React from 'react';
import { Navigate } from 'react-router-dom';

import { getStepTitle } from '../../conf/conf';

import appStore from '../../redux/store/appStore';
import { updateGui } from '../../redux/actions/actions';

import StepZilla from 'react-stepzilla';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';

import {
    config as wizardConfig,
    wizardReady as wizardHelperWizardReady,
    customizeButtons as wizardHelperCustomizeButtons,
    assignEvents as wizardHelperAssignEvents,
    getStepFromPathname,
} from '../../helpers/wizard-helper';

import { navigationByWizardControlsStart } from '../../helpers/browser-navigation-helper';

import { getItem } from '../../helpers/object-helper';

import './Wizard.css';

const steps = [
    { name: 'Step 1', component: <Step1 /> },
    { name: 'Step 2', component: <Step2 /> },
    { name: 'Step 3', component: <Step3 /> },
    { name: 'Step 4', component: <Step4 /> },
    { name: 'Step 5', component: <Step5 /> },
];

/**
 * StepZilla configuration
 */
const conf = {
    stepsNavigation: false,
    nextButtonText: 'Avanti',
    backButtonText: 'Indietro',
    /**
     * Event listener run every time the StepZilla step number changes
     *  1. Dispatches stepNumber
     *  2. Customize wizard nav buttons
     *  3. Dispatch button nav clicked
     * @param {Number} step
     *  The step number 0-indexed (this is why it is incremented step++)
     * @this
     *  this in this method represents the StepZilla configuration Object
     */
    onStepChange: function (step) {
        step++;
        dispatchStepNumber(step);
        wizardHelperCustomizeButtons(step);
    },
};

/**
 * Dispatches the gui.currentStep redux state update
 * @param {Number} step
 */
const dispatchStepNumber = (step) => {
    appStore.dispatch(
        updateGui({
            currentStep: step,
            currentStepTitle: getStepTitle(step),
        })
    );
};

const Wizard = (props) => {
    const [redirect, setRedirect] = React.useState(false);

    React.useEffect(() => {
        wizardConfig({
            setRedirectFn: setRedirect,
            nextButtonValidateFn: () => {
                const data = getItem(
                    'configuration.electricityConsumption',
                    appStore.getState()
                );
                return data !== null;
            },
            /**
             * Event listener for Wizard next button click
             * Starts 'navigation by wizard controls' state
             * 'navigation by wizard controls' state ends as soon as the next Step is mounted
             */
            nextButtonCustomClickListenerFn: () => {
                navigationByWizardControlsStart();
            },
            /**
             * Event listener for Wizard prev button click
             * Starts navigation by wizard controls state
             * 'navigation by wizard controls' state ends as soon as the prev Step is mounted
             */
            prevButtonCustomClickListenerFn: () => {
                navigationByWizardControlsStart();
            },
        });
    }, []);

    React.useEffect(() => {
        const stepFromPathname = getStepFromPathname(
            document.location.pathname
        );
        const step = stepFromPathname ? parseInt(stepFromPathname) : 1;
        dispatchStepNumber(step);
        wizardHelperWizardReady(() => {
            wizardHelperCustomizeButtons(step);
            wizardHelperAssignEvents();
        });
        return () => {
            dispatchStepNumber(0);
        };
    }, []);

    if (redirect !== false) {
        return <Navigate to={redirect} />;
    }

    return (
        <div className="step-progress slc-wizard">
            <StepZilla
                steps={steps}
                stepsNavigation={conf.stepsNavigation}
                nextButtonText={conf.nextButtonText}
                backButtonText={conf.backButtonText}
                nextTextOnFinalActionStep={conf.nextTextOnFinalActionStep}
                onStepChange={conf.onStepChange}
            />
        </div>
    );
};

export default Wizard;
