import PropTypes from 'prop-types';

import './FormCouponDiscounts.css';

const FormCouponDiscounts = ({ coupon }) => {
    return (
        <div className="slc-coupon-form__discounts">
            <div className="slc-coupon-form__discounts__totale">
                Totale scontato: {coupon.totale} &euro;
            </div>
            <div className="slc-coupon-form__discounts__instalments">
                Rata scontata: {coupon.instalments} &euro;
            </div>
        </div>
    );
};

FormCouponDiscounts.propTypes = {
    coupon: PropTypes.shape({
        type: PropTypes.oneOf(['valid']).isRequired,
        coupon: PropTypes.string.isRequired,
        totale: PropTypes.string.isRequired,
        instalments: PropTypes.string.isRequired,
    }),
};

export default FormCouponDiscounts;
