/**
 * Header Component
 * Redux connected
 * 
 * Can show or not show the title bar, driven by props.title
 * 
 * @prop {String} title
 */

import React from 'react';

import { connect } from "react-redux";

import useNavPath from '../hooks/useNavPath';

import HeaderTitle from './HeaderTitle';

import { isHomePage } from '../helpers/browser-navigation-helper';

import './Header.css';

import wolmannLogo from '../images/logo/wolmann-logo-header.png';

const mapStateToProps = state => {
    return {
        title: state.gui.currentStepTitle
    };
};

const Header = (props) => {

    const [navPath, setNavPath, navigateToNavPath] = useNavPath();

    const onClickWolmann = (evt) => {
        if (!isHomePage()) {
            setNavPath('/');
        }
    };

    return (navPath !== null) ?
        navigateToNavPath() :
        (
            <div className='slc-header'>
                <div className='slc-header-logo'>
                    <img className="slc-header-logo__image" src={wolmannLogo} alt="Wolmann" onClick={onClickWolmann} />
                </div>
                <HeaderTitle title={props.title} />
            </div>
        );

}

// Export unwrapped component in order to test it in isolation from Redux
export {
    Header
};

// Export Redux connected component as default
export default connect(mapStateToProps)(Header);