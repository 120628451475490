import React from 'react';

/**
 * @prop {String} id
 * @prop {String} desc
 * @prop {Boolean} si
 * @prop {Function} changeConsensoListener
 */
const FormConsensoItem = (props) => {

    const rootClassName = () => {
        return props.id + ' slc-form-input-checkbox-widget slc-quotations-form__consenso';
    }

    return (
        <div className={rootClassName()} id={props.id} onClick={props.changeFormCheckboxListener}>
            <div className="slc-form-input-checkbox-widget__input-container">
                <input 
                    type="checkbox"
                    className={`slc-checkbox slc-form-input-checkbox-widget__input slc-form-input-checkbox-widget__input-${ props.id }`}>
                </input>
                <div className="slc-quotations-form__consenso__content" dangerouslySetInnerHTML={{__html: props.content}} />
            </div>
        </div>
    );

}

export default FormConsensoItem;