import appStore from '../redux/store/appStore';

/**
 * Returns the list of all the batteries.
 * Reads from appStore.batteries[quotationType].
 * @param {*} quotationType
 *  Range
 *      ['basic'|'plus']
 * @returns {Object[]}
 *  [n]
 *      .code {String}
 *      .label {String}
 */
export const getBatteries = (quotationType) => {
    return (appStore.getState().batteries)
        ? appStore.getState().batteries[quotationType]
        : null;
};

/**
 * Returns the batteries set related to power for quotationType quotation.
 * Reads from altquotations[quotationType].quotations[power].
 * @param {String} quotationType
 *  Range
 *      ['basic'|'plus']
 * @param {String} power
 *  Sample
 *      '2,01 kWp'
 * @param {*} altquotations 
 * @returns {Object[]}
 *  [n]
 *      .code {String}
 *      .label {String}
 */
export const getBatteriesForPower = (quotationType, power, altquotations = appStore.getState().altquotations) => {

    const allBatteries = getBatteries(quotationType);

    if (! altquotations || ! altquotations[quotationType].quotations[power] || ! allBatteries) {
        return [];
    }

    const batteriesForPowerCodes = Object.keys(altquotations[quotationType].quotations[power]);
    const batteriesForPower = allBatteries.filter(battery => {
        return batteriesForPowerCodes.includes(battery.code);
    });

    return batteriesForPower;

};

/**
 * Returns if batteryLabel battery is a possible battery for power
 * @param {String} quotationType 
 *  Range
 *      ['basic'|'plus']
 * @param {String} batteryLabel
 *  Sample
 *      '5 kWh (inv. 5 kWh)'
 * @param {String} power 
 *  Sample
 *      '4,36 kWp'
 * @returns {Boolean}
 */
export const batteryBelongsToPower = (quotationType, batteryLabel, power) => {
    const batteryCode = getBatteryCodeByLabel(batteryLabel, quotationType);
    const batteriesForPowerCodes = Object.keys(appStore.getState().altquotations[quotationType].quotations[power]);
    return batteriesForPowerCodes.includes(batteryCode);
};

/**
 * Returns the best battery for power
 * @param {String} quotationType 
 *  Range
 *      ['basic'|'plus']
 * @param {String} power 
 *  Sample
 *      '4,36 kWp'
 * @returns {Object}
 *  .code {String}
 *  .label {String}
 */
export const getBestBatteryForPower = (quotationType, power) => {
    const batteriesForPower = getBatteriesForPower(quotationType, power);
    const bestBatteryForPower = getBestBattery(batteriesForPower);
    return bestBatteryForPower;
};

/**
 * Returns the best battery from batteries.
 * At the moment, the first battery is arbitrarily chosen.
 * @param {Object[]} batteries 
 * @returns {Object}
 *  .code {String}
 *  .label {String}
 */
export const getBestBattery = (batteries) => {
    return batteries[0];
};

export const getBatteryByProp = (propName, propValue, quotationType, batteries) => {
    batteries = batteries || getBatteries(quotationType);
    const filtered = batteries.filter(battery => battery[propName] === propValue);
    return (filtered.length > 0) ?
        filtered[0] :
        null;
};

export const getBatteryByCode = (code, quotationType, batteries) => {
    batteries = batteries || getBatteries(quotationType);
    return getBatteryByProp('code', code, quotationType, batteries);
};

export const getBatteryByLabel = (label, quotationType, batteries) => {
    batteries = batteries || getBatteries(quotationType);
    return getBatteryByProp('label', label, quotationType, batteries);
};

export const getBatteryCodeByLabel = (label, quotationType, batteries) => {
    batteries = batteries || getBatteries(quotationType);
    const battery = getBatteryByLabel(label, quotationType, batteries);
    return (battery) ?
        battery.code :
        null;
};

export const getBatteryLabelByCode = (code, quotationType, batteries) => {
    batteries = batteries || getBatteries();
    const battery = getBatteryByCode(code, quotationType, batteries);
    return (battery) ?
        battery.label :
        null;
};
