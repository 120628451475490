import React from 'react';

import './Footer.css';

import logoWolmann from '../images/wolmann-logo-footer.jpg';

const Footer = () => {

    return (
        <div className="ikea-wolmann-info">
            <div className="ikea-wolmann-info__logos">
                <img src={ logoWolmann } className="ikea-wolmann-info__logo ikea-wolmann-info__logo__wolmann" alt="" />
            </div>
            <div className="ikea-wolmann-info__text">
            Crediamo nella forza dell’energia solare come motore di uno sviluppo sostenibile, come traguardo per le prossime generazioni, come impegno per un pianeta più pulito.<br />
            Con i nostri Sistemi Solari abbiamo messo a tua disposizione la fonte di energia più sostenibile: il sole.<br />
            Con i pannelli solari e le batterie puoi sfruttare l’energia del sole per il tuo fabbisogno energetico in casa e usare l'elettricità in modo più efficiente. I pannelli solari ti aiutano a risparmiare sulle bollette elettriche contribuendo a salvaguardare il pianeta.<br />
            Ecco perché, con l'obiettivo di combattere i cambiamenti climatici, abbiamo creato soluzioni dal prezzo accessibile, in modo da renderle disponibili a più persone.
            </div>
        </div>
    );

};

export default Footer;