/**
 * Step3 Component: roofInclination
 */

import React, { Component, Fragment } from 'react';

import { getValueGuiFn } from '../../../conf/conf';

import appStore from '../../../redux/store/appStore';
import { updateGui, updateConfiguration } from '../../../redux/actions/actions';

import Step from './Step';

import { nextPermissionDeniedGuiFeedback } from '../../../helpers/wizard-helper';

import './Step3.css';

import { getItem } from '../../../helpers/object-helper';

// Menu related imports

import MenuSelector from '../../../helpers/MenuSelector';

import option0Img from '../../../images/step3/0.png';
import option0ImgSelected from '../../../images/step3/0_selected.png';

import option20Img from '../../../images/step3/20.png';
import option20ImgSelected from '../../../images/step3/20_selected.png';

import option40Img from '../../../images/step3/40.png';
import option40ImgSelected from '../../../images/step3/40_selected.png';

const images = {
    option0: [option0Img, option0ImgSelected],
    option20: [option20Img, option20ImgSelected],
    option40: [option40Img, option40ImgSelected]
};

const stepNum = 3;
const editingDataId = 'configuration.roofInclination';
const options = [0, 20, 40];
const selectedClass = 'selected';

class Step3 extends Component {

    constructor(props) {
        super(props);
        this.editingDataId = editingDataId;
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {

        appStore.dispatch(updateGui({
            currentEditingData: editingDataId
        }));

        const menuSelector = new MenuSelector({
            itemsElements: document.querySelectorAll('.slc-roofInclination-option'),
            selectedElement: this.getSelectedOptionFromStore(),
            guiHiliteFn: this.optionGuiHilite.bind(this),
            guiDehiliteFn: this.optionGuiDehilite.bind(this),
            // update store on item click
            onItemClickFn: (itemElement) => {
                appStore.dispatch(updateConfiguration({
                    roofInclination: this.getOptionElementValue(itemElement)
                }));
            }
        });

    }

    isValidated(showWarnMessage) {
        const data = getItem(editingDataId, appStore.getState());
        const valid = (data !== null);
        if (!valid && showWarnMessage === undefined) {
            nextPermissionDeniedGuiFeedback(this, stepNum, true);
        }
        return (data !== null);
    }

    /**
     * Returns the optionElement value, reading it from the 'data-value' element attribute
     */
    getOptionElementValue(optionElement) {
        return optionElement.getAttribute('data-value');
    }

    /**
     * Reads from the store the value of the piece of data related to this step ('configuration.roofInclination')
     *  a. If a value is found, the option HTMLElement representing that value is returned
     *  b. Otherwise, null is returned
     * @return {HTMLElement|null}
     */
    getSelectedOptionFromStore() {
        const roofInclination = getItem(editingDataId, appStore.getState()); 
        return (roofInclination !== null) ?
            document.querySelector('.slc-roofInclination-option-' + roofInclination) :
            null;
    };

    /**
     * Hilites optionElement
     * Hilite is accomplished through 2 updates:
     *  1. Option image src
     *  2. Option label bg color (through the 'selected' class usage)
     * @param {HTMLElement} optionElement 
     */
    optionGuiHilite(optionElement) {
        // get necessary data
        const optionElementValue = this.getOptionElementValue(optionElement);
        const imageElement = optionElement.childNodes[0];
        // hilite label
        optionElement.classList.add(selectedClass);
        // hilite image
        imageElement.src = images['option' + optionElementValue][1];
    }

    /**
     * Dehilites optionElement
     * Dehilite is accomplished through 2 updates:
     *  1. Option image src
     *  2. Option label bg color (through the 'selected' class usage)
     * @param {HTMLElement} optionElement 
     */
    optionGuiDehilite(optionElement) {
        // get necessary data
        const optionElementValue = this.getOptionElementValue(optionElement);
        const imageElement = optionElement.childNodes[0];
        // dehilite label
        optionElement.classList.remove(selectedClass);
        // dehilite image
        imageElement.src = images['option' + optionElementValue][0];
    };

    /**
     * Returns the JSX body for this Step<N> Component
     * The body is passed as a prop to the Step Component, which is actually rendered
     */
    body() {
        return (
            <Fragment>
                <div className="slc-roofInclination">
                    {this.bodyGetOptionsHtml()}
                </div>
            </Fragment>
        );
    }

    /**
     * Returns the JSX for all the options
     * @return JSX
     */
    bodyGetOptionsHtml() {
        return options.map((option) => {
            return this.bodyGetOptionHtml(option);
        });
    }

    /**
     * Returns the JSX for the optionValue cardinal point
     * @param {Number} optionValue
     * @return JSX
     */
    bodyGetOptionHtml(optionValue) {
        return (
            <div
                key={optionValue}
                className={'slc-roofInclination-option slc-roofInclination-option-' + optionValue}
                data-value={optionValue}>
                <img
                    src={images['option' + optionValue][0]} className="slc-roofInclination-option-image" alt={optionValue + '&deg;'} />
                <div
                    className="slc-roofInclination-option-label slc-circle-fit-to-content slc-option-label">{getValueGuiFn(editingDataId)(optionValue)}
                </div>
            </div>
        );
    }

    render() {
        return <Step stepComponent={this} stepNum={stepNum} stepBody={this.body()} />;
    }
    
}

export default Step3;